import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type MenuState = {
	isMenuExpanded: boolean;
	toggleMenu: () => void;
	setPlayers: (num: number) => void;
	players: number;
};

export const useMenuState = create(
	persist(
		immer<MenuState>((set) => ({
			isMenuExpanded: true,
			players: 0,
			toggleMenu: () =>
				set((state) => {
					state.isMenuExpanded = !state.isMenuExpanded;
				}),
			setPlayers: (num: number) =>
				set((state) => {
					state.players = num;
				}),
		})),
		{
			name: 'menu-state',
			version: 1,
		}
	)
);
