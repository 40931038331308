import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { HiTrash } from 'react-icons/hi2';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const DeleteButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={HiTrash} variant={'error'} {...props} ref={ref} aria-label="Delete">
			<Trans i18nKey={'common:actions.delete'}>Delete {{ value: children || '' }}</Trans>
		</Button>
	);
});

DeleteButton.displayName = 'DeleteButton';
