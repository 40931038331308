import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { Button, IButtonProps } from '../Button';

export const YesButton = forwardRef<HTMLButtonElement, Omit<IButtonProps, 'children'>>(({ ...props }, ref) => {
	return (
		<Button variant={'success'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.yes'}>Yes</Trans>
		</Button>
	);
});

YesButton.displayName = 'YesButton';
