import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { HiArrowLeft } from 'react-icons/hi2';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const PreviousButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={HiArrowLeft} variant={'primary'} contrast={'outline'} {...props} ref={ref}>
			<Trans i18nKey={'common:labels.previous'}>Next {{ value: children || '' }}</Trans>
		</Button>
	);
});

PreviousButton.displayName = 'PreviousButton';
