import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export enum Theme {
	LIGHT = 'light',
	DARK = 'dark',
	COLOR_BLIND = 'colorBlind',
}

type TUserSettingsStoreType = {
	theme: Theme;
	rounding: number;
	isMenuExpanded: boolean;
	toggleMenu: () => void;
	setTheme: (theme: Theme) => void;
	setRounding: (n: number) => void;
};

export const useUserSettings = create(
	persist(
		immer<TUserSettingsStoreType>((set) => ({
			theme: Theme.LIGHT,
			isMenuExpanded: true,
			rounding: -1,
			toggleMenu: () =>
				set((state) => {
					state.isMenuExpanded = !state.isMenuExpanded;
				}),
			setTheme: (theme: Theme) =>
				set((state) => {
					state.theme = theme;
				}),
			setRounding: (n: number) =>
				set((state) => {
					state.rounding = n;
				}),
		})),
		{
			name: 'user-settings-storage',
			version: 1,
		}
	)
);
