export const NumberMinMaxFilterStats = [
	'wy_per_90_accelerations',
	'wy_per_90_aerial_duels',
	'wy_per_90_aerial_duels_won',
	'wy_per_90_assists',
	'wy_per_90_attacking_actions',
	'wy_per_90_back_passes',
	'wy_per_90_clearances',
	'wy_per_90_corners',
	'wy_per_90_counterpressing_recoveries',
	'wy_per_90_crosses',
	'wy_per_90_dangerous_opponent_half_recoveries',
	'wy_per_90_dangerous_own_half_losses',
	'wy_per_90_defensive_actions',
	'wy_per_90_defensive_duels',
	'wy_per_90_direct_free_kicks',
	'wy_per_90_direct_free_kicks_on_target',
	'wy_per_90_direct_red_cards',
	'wy_per_90_dribbles',
	'wy_per_90_dribbles_against',
	'wy_per_90_dribbles_against_won',
	'wy_per_90_duels',
	'wy_per_90_field_aerial_duels',
	'wy_per_90_field_aerial_duels_won',
	'wy_per_90_forward_passes',
	'wy_per_90_fouls',
	'wy_per_90_fouls_suffered',
	'wy_per_90_free_kicks',
	'wy_per_90_free_kicks_on_target',
	'wy_per_90_gk_aerial_duels',
	'wy_per_90_gk_aerial_duels_won',
	'wy_per_90_gk_clean_sheets',
	'wy_per_90_gk_conceded_goals',
	'wy_per_90_gk_exits',
	'wy_per_90_gk_saves',
	'wy_per_90_gk_shots_against',
	'wy_per_90_gk_successful_exits',
	'wy_per_90_goal_kicks',
	'wy_per_90_goal_kicks_long',
	'wy_per_90_goal_kicks_short',
	'wy_per_90_goals',
	'wy_per_90_head_shots',
	'wy_per_90_interceptions',
	'wy_per_90_key_passes',
	'wy_per_90_lateral_passes',
	'wy_per_90_linkup_plays',
	'wy_per_90_long_passes',
	'wy_per_90_loose_ball_duels',
	'wy_per_90_loose_ball_duels_won',
	'wy_per_90_losses',
	'wy_per_90_missed_balls',
	'wy_per_90_new_defensive_duels_won',
	'wy_per_90_new_duels_won',
	'wy_per_90_new_offensive_duels_won',
	'wy_per_90_new_successful_dribbles',
	'wy_per_90_offensive_duels',
	'wy_per_90_offsides',
	'wy_per_90_opponent_half_recoveries',
	'wy_per_90_own_half_losses',
	'wy_per_90_passes',
	'wy_per_90_passes_to_final_third',
	'wy_per_90_penalties',
	'wy_per_90_pressing_duels',
	'wy_per_90_pressing_duels_won',
	'wy_per_90_progressive_passes',
	'wy_per_90_progressive_run',
	'wy_per_90_received_pass',
	'wy_per_90_recoveries',
	'wy_per_90_red_cards',
	'wy_per_90_second_assists',
	'wy_per_90_shot_assists',
	'wy_per_90_shot_on_target_assists',
	'wy_per_90_shots',
	'wy_per_90_shots_blocked',
	'wy_per_90_shots_on_target',
	'wy_per_90_sliding_tackles',
	'wy_per_90_smart_passes',
	'wy_per_90_successful_attacking_actions',
	'wy_per_90_successful_back_passes',
	'wy_per_90_successful_crosses',
	'wy_per_90_successful_defensive_action',
	'wy_per_90_successful_forward_passes',
	'wy_per_90_successful_goal_kicks',
	'wy_per_90_successful_key_passes',
	'wy_per_90_successful_lateral_passes',
	'wy_per_90_successful_linkup_plays',
	'wy_per_90_successful_long_passes',
	'wy_per_90_successful_passes',
	'wy_per_90_successful_passes_to_final_third',
	'wy_per_90_successful_penalties',
	'wy_per_90_successful_progressive_passes',
	'wy_per_90_successful_sliding_tackles',
	'wy_per_90_successful_smart_passes',
	'wy_per_90_successful_through_passes',
	'wy_per_90_successful_vertical_passes',
	'wy_per_90_third_assists',
	'wy_per_90_through_passes',
	'wy_per_90_touch_in_box',
	'wy_per_90_vertical_passes',
	'wy_per_90_xg_assist',
	'wy_per_90_xg_save',
	'wy_per_90_xg_shot',
	'wy_per_90_yellow_cards',
	'wy_percent_aerial_duels_won',
	'wy_percent_direct_free_kicks_on_target',
	'wy_percent_dribbles_against_won',
	'wy_percent_field_aerial_duels_won',
	'wy_percent_gk_aerial_duels_won',
	'wy_percent_gk_saves',
	'wy_percent_gk_successful_exits',
	'wy_percent_goal_conversion',
	'wy_percent_head_shots_on_target',
	'wy_percent_new_defensive_duels_won',
	'wy_percent_new_duels_won',
	'wy_percent_new_offensive_duels_won',
	'wy_percent_new_successful_dribbles',
	'wy_percent_penalties_conversion',
	'wy_percent_shots_on_target',
	'wy_percent_successful_back_passes',
	'wy_percent_successful_crosses',
	'wy_percent_successful_forward_passes',
	'wy_percent_successful_goal_kicks',
	'wy_percent_successful_key_passes',
	'wy_percent_successful_lateral_passes',
	'wy_percent_successful_linkup_plays',
	'wy_percent_successful_long_passes',
	'wy_percent_successful_passes',
	'wy_percent_successful_passes_to_final_third',
	'wy_percent_successful_progressive_passes',
	'wy_percent_successful_shot_assists',
	'wy_percent_successful_sliding_tackles',
	'wy_percent_successful_smart_passes',
	'wy_percent_successful_through_passes',
	'wy_percent_successful_vertical_passes',
	'wy_percent_win',
	'wy_percent_yellow_cards_per_foul',
	'wy_totals_matches',
	'wy_totals_matches_coming_off',
	'wy_totals_matches_in_start',
	'wy_totals_matches_substituted',
	'wy_totals_minutes_on_field',
	'wy_totals_minutes_tagged',
	'sc_per_90_distance',
	'sc_per_90_sprinting_distance',
	'sc_per_90_hsr_distance',
	'sc_per_90_running_distance',
	'sc_per_90_count_hsr',
	'sc_per_90_count_sprint',
	'sc_per_90_count_medium_acceleration',
	'sc_per_90_count_medium_deceleration',
	'sc_per_90_count_high_acceleration',
	'sc_per_90_count_high_deceleration',
	'sc_per_90_distance_tip',
	'sc_per_90_sprinting_distance_tip',
	'sc_per_90_hsr_distance_tip',
	'sc_per_90_running_distance_tip',
	'sc_per_90_count_hsr_tip',
	'sc_per_90_count_sprint_tip',
	'sc_per_90_count_medium_acceleration_tip',
	'sc_per_90_count_medium_deceleration_tip',
	'sc_per_90_count_high_acceleration_tip',
	'sc_per_90_count_high_deceleration_tip',
	'sc_per_90_distance_otip',
	'sc_per_90_sprinting_distance_otip',
	'sc_per_90_hsr_distance_otip',
	'sc_per_90_running_distance_otip',
	'sc_per_90_count_hsr_otip',
	'sc_per_90_count_sprint_otip',
	'sc_per_90_count_medium_acceleration_otip',
	'sc_per_90_count_medium_deceleration_otip',
	'sc_per_90_count_high_acceleration_otip',
	'sc_per_90_count_high_deceleration_otip',
	'sc_totals_psv_99',
	'sc_totals_top_5_psv_99',
	'sc_totals_minutes_tip',
	'sc_totals_minutes_otip',
	'sc_totals_minutes',
	'sc_totals_matches',
	'sc_totals_matches_failed',
] as const;

export type NumberMinMaxFilterStats = (typeof NumberMinMaxFilterStats)[number];

export const NumberMinMaxFilterPlayer = ['age', 'height', 'weight'] as const;

export type NumberMinMaxFilterPlayer = (typeof NumberMinMaxFilterPlayer)[number];

export const NumberMinMaxCompetitionStats = ['divisionLevel'] as const;

export type NumberMinMaxCompetitionStats = (typeof NumberMinMaxCompetitionStats)[number];

export const MinMaxCompetitionDefaults: { [key in NumberMinMaxCompetitionStats]: [number, number, number] } = {
	divisionLevel: [0, 5, 1],
};

export const MinMaxPlayerDefaults: { [key in NumberMinMaxFilterPlayer]: [number, number, number] } = {
	age: [9, 44, 1],
	height: [0, 255, 1],
	weight: [0, 182, 1],
};

export const MinMaxStatsDefaults: { [key in NumberMinMaxFilterStats]: [number, number, number] } = {
	wy_per_90_accelerations: [0, 2.0, 0.1],
	wy_per_90_aerial_duels: [0, 10, 0.5],
	wy_per_90_aerial_duels_won: [0, 5, 0.25],
	wy_per_90_assists: [0, 0.5, 0.025],
	wy_per_90_attacking_actions: [0, 15, 0.5],
	wy_per_90_back_passes: [0, 10, 0.5],
	wy_per_90_clearances: [0, 4, 0.2],
	wy_per_90_corners: [0, 3, 0.1],
	wy_per_90_counterpressing_recoveries: [0, 7.5, 0.25],
	wy_per_90_crosses: [0, 5, 0.25],
	wy_per_90_dangerous_opponent_half_recoveries: [0, 1, 0.05],
	wy_per_90_dangerous_own_half_losses: [0, 2, 0.1],
	wy_per_90_defensive_actions: [0, 20, 1],
	wy_per_90_defensive_duels: [0, 12.5, 0.5],
	wy_per_90_direct_free_kicks: [0, 0.5, 0.025],
	wy_per_90_direct_free_kicks_on_target: [0, 0.5, 0.025],
	wy_per_90_direct_red_cards: [0, 0.25, 0.01],
	wy_per_90_dribbles: [0, 10, 0.5],
	wy_per_90_dribbles_against: [0, 5, 0.25],

	wy_per_90_dribbles_against_won: [0, 2.5, 0.1],
	wy_per_90_duels: [0, 35, 1],

	wy_per_90_field_aerial_duels: [0, 10, 0.5],
	wy_per_90_field_aerial_duels_won: [0, 5, 0.25],
	wy_per_90_forward_passes: [0, 25, 1],
	wy_per_90_fouls: [0, 3, 0.1],
	wy_per_90_fouls_suffered: [0, 3, 0.1],
	wy_per_90_free_kicks: [0, 2, 0.1],
	wy_per_90_free_kicks_on_target: [0, 1, 0.05],
	wy_per_90_gk_aerial_duels: [0, 1.25, 0.05],
	wy_per_90_gk_aerial_duels_won: [0, 1.25, 0.05],
	wy_per_90_gk_clean_sheets: [0, 0.5, 0.025],
	wy_per_90_gk_conceded_goals: [0, 5, 0.25],
	wy_per_90_gk_exits: [0, 3, 0.1],
	wy_per_90_gk_saves: [0, 5, 0.25],
	wy_per_90_gk_shots_against: [0, 10, 0.5],
	wy_per_90_gk_successful_exits: [0, 0, 0],

	wy_per_90_goal_kicks: [0, 12.5, 0.5],
	wy_per_90_goal_kicks_long: [0, 10, 0.5],
	wy_per_90_goal_kicks_short: [0, 10, 0.5],
	wy_per_90_goals: [0, 1, 0.025],
	wy_per_90_head_shots: [0, 1, 0.025],
	wy_per_90_interceptions: [0, 7.5, 0.25],
	wy_per_90_key_passes: [0, 1, 0.05],
	wy_per_90_lateral_passes: [0, 30, 1],
	wy_per_90_linkup_plays: [0, 5, 0.25],
	wy_per_90_long_passes: [0, 10, 0.5],
	wy_per_90_loose_ball_duels: [0, 7.5, 0.25],
	wy_per_90_loose_ball_duels_won: [0, 2.5, 0.1],
	wy_per_90_losses: [0, 20, 1],
	wy_per_90_missed_balls: [0, 2, 0.1],
	wy_per_90_new_defensive_duels_won: [0, 7.5, 0.25],
	wy_per_90_new_duels_won: [0, 15, 0.5],
	wy_per_90_new_offensive_duels_won: [0, 7.5, 0.25],
	wy_per_90_new_successful_dribbles: [0, 5, 0.25],
	wy_per_90_offensive_duels: [0, 20, 1],

	wy_per_90_offsides: [0, 2, 0.1],
	wy_per_90_opponent_half_recoveries: [0, 5, 0.25],
	wy_per_90_own_half_losses: [0, 7, 0.25],
	wy_per_90_passes: [0, 60, 2],
	wy_per_90_passes_to_final_third: [0, 10, 0.5],
	wy_per_90_penalties: [0, 0.25, 0.01],
	wy_per_90_pressing_duels: [0, 10, 0.5],

	wy_per_90_pressing_duels_won: [0, 0, 0],

	wy_per_90_progressive_passes: [0, 12.5, 0.5],
	wy_per_90_progressive_run: [0, 3.5, 0.1],
	wy_per_90_received_pass: [0, 50, 2],
	wy_per_90_recoveries: [0, 15, 0.5],
	wy_per_90_red_cards: [0, 0.25, 0.01],
	wy_per_90_second_assists: [0, 0.25, 0.01],
	wy_per_90_shot_assists: [0, 2, 0.1],
	wy_per_90_shot_on_target_assists: [0, 1, 0.05],
	wy_per_90_shots: [0, 4, 0.2],
	wy_per_90_shots_blocked: [0, 1, 0.05],
	wy_per_90_shots_on_target: [0, 2, 0.1],
	wy_per_90_sliding_tackles: [0, 1.5, 0.05],
	wy_per_90_smart_passes: [0, 2, 0.1],
	wy_per_90_successful_attacking_actions: [0, 7.5, 0.25],
	wy_per_90_successful_back_passes: [0, 10, 0.5],
	wy_per_90_successful_crosses: [0, 2, 0.1],
	wy_per_90_successful_defensive_action: [0, 15, 0.5],
	wy_per_90_successful_forward_passes: [0, 20, 1],
	wy_per_90_successful_goal_kicks: [0, 10, 0.5],
	wy_per_90_successful_key_passes: [0, 1, 0.05],
	wy_per_90_successful_lateral_passes: [0, 25, 1],
	wy_per_90_successful_linkup_plays: [0, 4, 0.2],
	wy_per_90_successful_long_passes: [0, 6, 0.25],
	wy_per_90_successful_passes: [0, 50, 2.5],
	wy_per_90_successful_passes_to_final_third: [0, 7.5, 0.25],
	wy_per_90_successful_penalties: [0, 0.2, 0.01],
	wy_per_90_successful_progressive_passes: [0, 10, 0.5],
	wy_per_90_successful_sliding_tackles: [0, 1, 0.05],
	wy_per_90_successful_smart_passes: [0, 1, 0.05],
	wy_per_90_successful_through_passes: [0, 0.75, 0.025],
	wy_per_90_successful_vertical_passes: [0, 25, 1],
	wy_per_90_third_assists: [0, 0.2, 0.01],
	wy_per_90_through_passes: [0, 2, 0.1],
	wy_per_90_touch_in_box: [0, 5, 0.25],
	wy_per_90_vertical_passes: [0, 25, 1],
	wy_per_90_xg_assist: [0, 0.3, 0.01],
	wy_per_90_xg_save: [0, 2.5, 0.1],
	wy_per_90_xg_shot: [0, 0.75, 0.025],
	wy_per_90_yellow_cards: [0, 0.75, 0.025],

	wy_percent_aerial_duels_won: [0, 70, 2.5],
	wy_percent_direct_free_kicks_on_target: [0, 100, 5],
	wy_percent_dribbles_against_won: [15, 75, 2.5],
	wy_percent_field_aerial_duels_won: [0, 75, 2.5],
	wy_percent_gk_aerial_duels_won: [0, 100, 5],
	wy_percent_gk_saves: [50, 85, 1],

	wy_percent_goal_conversion: [0, 40, 2],
	wy_percent_head_shots_on_target: [0, 100, 5],
	wy_percent_new_defensive_duels_won: [40, 80, 2],
	wy_percent_new_duels_won: [30, 65, 1],
	wy_percent_new_offensive_duels_won: [15, 75, 2.5],
	wy_percent_new_successful_dribbles: [25, 75, 2.5],
	wy_percent_penalties_conversion: [0, 100, 5],

	wy_percent_shots_on_target: [0, 75, 2.5],
	wy_percent_successful_back_passes: [75, 100, 1],
	wy_percent_successful_crosses: [0, 60, 2.5],

	wy_percent_successful_forward_passes: [35, 85, 2.5],
	wy_percent_successful_goal_kicks: [70, 100, 1],
	wy_percent_successful_key_passes: [0, 100, 5],
	wy_percent_successful_lateral_passes: [50, 100, 2.5],
	wy_percent_successful_linkup_plays: [40, 100, 2.5],
	wy_percent_successful_long_passes: [20, 80, 2.5],
	wy_percent_successful_passes: [60, 90, 1],
	wy_percent_successful_passes_to_final_third: [30, 90, 2],
	wy_percent_successful_progressive_passes: [45, 95, 2.5],
	wy_percent_successful_shot_assists: [25, 75, 2.5],
	wy_percent_successful_sliding_tackles: [25, 75, 2.5],
	wy_percent_successful_smart_passes: [25, 75, 2.5],
	wy_percent_successful_through_passes: [10, 40, 1],
	wy_percent_successful_vertical_passes: [50, 100, 2.5],
	wy_percent_win: [0, 80, 4],
	wy_percent_yellow_cards_per_foul: [0, 50, 2.5],

	wy_totals_matches: [0, 40, 2],
	wy_totals_matches_coming_off: [0, 20, 1],
	wy_totals_matches_in_start: [0, 40, 2],
	wy_totals_matches_substituted: [0, 15, 1],
	wy_totals_minutes_on_field: [0, 3000, 150],
	wy_totals_minutes_tagged: [0, 3000, 150],

	sc_per_90_distance: [8500, 11500, 150],
	sc_per_90_sprinting_distance: [50, 350, 15],
	sc_per_90_hsr_distance: [300, 800, 25],
	sc_per_90_running_distance: [900, 2200, 65],
	sc_per_90_count_hsr: [25, 65, 2],
	sc_per_90_count_sprint: [3, 18, 0.5],
	sc_per_90_count_medium_acceleration: [65, 115, 2.5],
	sc_per_90_count_medium_deceleration: [60, 100, 2],
	sc_per_90_count_high_acceleration: [3, 13, 0.5],
	sc_per_90_count_high_deceleration: [9, 24, 0.5],
	sc_per_90_distance_tip: [2750, 4750, 100],
	sc_per_90_sprinting_distance_tip: [0, 200, 10],
	sc_per_90_hsr_distance_tip: [0, 400, 20],
	sc_per_90_running_distance_tip: [300, 1000, 35],
	sc_per_90_count_hsr_tip: [0, 35, 1],
	sc_per_90_count_sprint_tip: [0, 10, 0.5],
	sc_per_90_count_medium_acceleration_tip: [17, 45, 1],
	sc_per_90_count_medium_deceleration_tip: [13, 30, 1],
	sc_per_90_count_high_acceleration_tip: [0, 4, 0.2],
	sc_per_90_count_high_deceleration_tip: [1, 6, 0.2],
	sc_per_90_distance_otip: [3000, 5000, 100],
	sc_per_90_sprinting_distance_otip: [25, 150, 5],
	sc_per_90_hsr_distance_otip: [150, 450, 10],
	sc_per_90_running_distance_otip: [450, 1200, 25],
	sc_per_90_count_hsr_otip: [15, 35, 1],
	sc_per_90_count_sprint_otip: [0, 7, 0.25],
	sc_per_90_count_medium_acceleration_otip: [20, 50, 1],
	sc_per_90_count_medium_deceleration_otip: [18, 38, 1],
	sc_per_90_count_high_acceleration_otip: [0, 3, 0.1],
	sc_per_90_count_high_deceleration_otip: [2.5, 8.5, 0.25],

	sc_totals_psv_99: [26, 31, 0.25],
	sc_totals_top_5_psv_99: [27, 33, 0.25],
	sc_totals_minutes_tip: [0, 32, 1],
	sc_totals_minutes_otip: [0, 32, 1],
	sc_totals_minutes: [0, 100, 5],
	sc_totals_matches: [1, 40, 1],

	wy_percent_gk_successful_exits: [0, 0, 0],
	sc_totals_matches_failed: [0, 0, 0],
};
