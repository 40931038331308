import { VariantProps, cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { IconType } from 'react-icons/lib';
import { cn } from '../util';
import { IInputProps, Input } from './Input';

const container = cva('flex items-center w-full ', {
	variants: {
		variant: {
			default: ['border-base-content/20   outline-base-content/30'],
			neutral: ['border-neutral  outline-neutral'],
		},
		cssSize: {
			xs: ['input-xs'],
			sm: ['h-8'],
			md: ['h-12'],
			lg: ['input-lg'],
		},
		isSuccess: {
			true: ['border-success outline-success'],
			false: [''],
		},
		isError: {
			true: ['border-error outline-error'],
			false: [''],
		},
		border: {
			true: ['border overflow-hidden'],
			false: [],
		},
		outline: {
			true: ['focus-within:outline outline-offset-2 outline-2'],
			false: [],
		},
		rounded: {
			inherit: [],
			lg: ['rounded-lg'],
			sm: ['rounded-sm'],
			md: ['rounded-md'],
			xs: ['rounded-xs'],
			xl: ['rounded-xl'],
		},
	},
	defaultVariants: {
		border: true,
		isError: false,
		isSuccess: false,
		cssSize: 'md',
		variant: 'default',
		rounded: 'inherit',
		outline: true,
	},
});

const iconContainer = cva('self-stretch flex items-center justify-center p-2 join-item cursor-pointer', {
	variants: {
		variant: {
			'100': ['text-base-content bg-base-100'],
			'200': ['text-base-content bg-base-200'],
			'300': ['text-base-content bg-base-300'],
			default: [],
			primary: ['bg-primary text-primary-content'],
			secondary: ['bg-secondary text-primary-content'],
			neutral: ['bg-neutral text-primary-content'],
			success: ['bg-success text-primary-content'],
			ghost: ['bg-ghost text-primary-content'],
			error: ['bg-error text-primary-content'],
			warning: ['bg-warning text-primary-content'],
			info: ['bg-info text-primary-content'],
		},
	},
	defaultVariants: {
		variant: 'default',
	},
});

const icon = cva('', {
	variants: {
		size: {
			xs: ['h-3 w-3'],
			sm: ['h-5 w-5'],
			md: ['h-6 w-6'],
			inherit: [''],
			lg: ['h-8 w-8'],
		},
	},
	defaultVariants: {
		size: 'inherit',
	},
});

type TIconSettings = VariantProps<typeof iconContainer> &
	VariantProps<typeof icon> &
	Omit<React.ComponentPropsWithoutRef<IconType>, 'size'>;

export type IInputGroupProps = IInputProps &
	VariantProps<typeof container> & {
		startIcon?: IconType;
		startElement?: React.ReactNode;
		endElement?: React.ReactNode;
		startIconSettings?: TIconSettings;
		endIconSettings?: TIconSettings;
		endIcon?: IconType;
	};

export const InputGroup = forwardRef<HTMLInputElement, IInputGroupProps>(
	(
		{
			startIcon: StartIcon,
			endIcon: EndIcon,
			border,
			startElement: StartElement,
			endElement: EndElement,
			startIconSettings: { variant: startVariant, size: startSize, ...startIconSettings } = {},
			isError,
			isSuccess,
			cssSize,
			rounded,
			variant,
			outline,
			endIconSettings: { variant: endVariant, size: endSize, ...endIconSettings } = {},
			...props
		},
		ref
	) => {
		return (
			<div
				className={container({
					border,
					isError,
					isSuccess,
					cssSize,
					rounded,
					variant,
					outline,
				})}
			>
				{StartIcon && (
					<label className={iconContainer({ variant: startVariant })} htmlFor={props.id}>
						<StartIcon
							{...startIconSettings}
							className={icon({ size: startSize, className: startIconSettings.className })}
						/>
					</label>
				)}
				{StartElement}
				<Input
					{...props}
					className={cn(
						props.className,
						'z-10 border-0 outline-none focus-within:outline-none h-full self-stretch rounded-none',
						{ 'pl-1': StartIcon }
					)}
					containerConfig={{
						className: cn('flex self-stretch', props.containerConfig?.className),
					}}
					outline={outline}
					border={false}
					ref={ref}
				/>
				{EndIcon && (
					<label className={iconContainer({ variant: endVariant })} htmlFor={props.id}>
						<EndIcon
							{...endIconSettings}
							className={icon({ size: endSize, className: endIconSettings.className })}
						/>
					</label>
				)}
				{EndElement}
			</div>
		);
	}
);

InputGroup.displayName = 'InputGroup';
