import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { Button, IButtonProps } from '../Button';

export const NoButton = forwardRef<HTMLButtonElement, Omit<IButtonProps, 'children'>>(({ ...props }, ref) => {
	return (
		<Button variant={'primary'} contrast={'outline'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.no'}>No</Trans>
		</Button>
	);
});

NoButton.displayName = 'NoButton';
