import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';
import { IconType } from 'react-icons/lib';
import { Icon } from '../images';
import { cn } from '../util';

const TabsRoot = React.forwardRef<React.ElementRef<typeof Tabs.Root>, React.ComponentPropsWithoutRef<typeof Tabs.Root>>(
	({ className, ...props }, ref) => (
		<Tabs.Root ref={ref} {...props} className={cn('flex flex-col w-full justify-center h-min', className)} />
	)
);
TabsRoot.displayName = Tabs.Root.displayName;

const TabsContent = React.forwardRef<
	React.ElementRef<typeof Tabs.Content>,
	React.ComponentPropsWithoutRef<typeof Tabs.Content>
>(({ className, ...props }, ref) => (
	<Tabs.Content
		ref={ref}
		{...props}
		className={cn(
			'flex items-start justify-start w-full gap-6 py-4 flex-col data-[state=inactive]:hidden',
			className
		)}
	/>
));
TabsContent.displayName = Tabs.Content.displayName;

const AnimatedTabsContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => (
		<div
			ref={ref}
			{...props}
			className={cn('flex w-full flex-col grow basis-full animate-in fade-in-0 slide-in-from-left-2', className)}
		/>
	)
);
AnimatedTabsContent.displayName = 'AnimatedTabsContent';

const TabsList = React.forwardRef<
	React.ElementRef<typeof Tabs.List>,
	React.ComponentPropsWithoutRef<typeof Tabs.List> & { tabs: number }
>(({ className, children, tabs, ...props }, ref) => (
	<Tabs.List ref={ref} {...props} className={cn('flex items-center justify-center ', className)}>
		<div
			className="grid gap-2 p-2 rounded-md shadow-sm bg-base-200"
			style={{
				gridTemplateColumns: `repeat(${tabs}, minmax(0, 1fr))`,
			}}
		>
			{children}
		</div>
	</Tabs.List>
));
TabsList.displayName = Tabs.List.displayName;

const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof Tabs.Trigger>,
	React.ComponentPropsWithoutRef<typeof Tabs.Trigger> & { icon: IconType }
>(({ className, children, icon, ...props }, ref) => (
	<Tabs.Trigger
		ref={ref}
		{...props}
		className={cn(
			'flex rounded-md items-center justify-center gap-1 p-2 text-xs after:transition-all after:w-full after:h-0 data-[state=active]:font-medium data-[state=active]:after:h-0.5 relative after:absolute after:bottom-0 data-[state=active]:after:bg-primary data-[state=active]:bg-base-100 data-[state=active]:hover:cursor-default data-[state=active]:shadow-inner data-[state=active]:text-primary hover:bg-base-300 hover:shadow-inner',
			className
		)}
	>
		<Icon icon={icon} />
		<div className="hidden md:block">{children}</div>
	</Tabs.Trigger>
));
TabsTrigger.displayName = Tabs.Trigger.displayName;

export { AnimatedTabsContent, TabsContent, TabsList, TabsRoot, TabsTrigger };
