import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { HiXMark } from 'react-icons/hi2';
import { IconType } from 'react-icons/lib';
import { Button } from '../buttons/Button';
import { cn } from '../util';

const DialogRoot = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({ ...props }: DialogPrimitive.DialogPortalProps) => <DialogPrimitive.Portal {...props} />;
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay
		ref={ref}
		className={cn(
			'fixed inset-0 z-50 bg-base-100/70 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
			className
		)}
		{...props}
	/>
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<DialogPortal>
		<DialogOverlay />
		<DialogPrimitive.Content
			ref={ref}
			{...props}
			className={cn(
				'fixed top-0 pointer-events-none left-0 w-full h-full rounded-md overflow-y-auto z-50 flex flex-col items-center justify-center text-base-content drop-shadow-xl px-2',
				'duration-200  data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-4 data-[state=closed]:fade-out-0',
				'data-[state=open]:animate-in data-[state=open]:slide-in-from-top-4 data-[state=open]:fade-in-0'
			)}
			style={{
				pointerEvents: 'none',
			}}
		>
			<div
				className={cn(
					'min-w-[30%] max-w-[80%] bg-base-100 border max-h-3/4  scrollbar overflow-auto flex flex-col',
					className
				)}
				style={{
					pointerEvents: 'auto',
				}}
			>
				{children}
			</div>
		</DialogPrimitive.Content>
	</DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const NewDialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay
		ref={ref}
		className={cn(
			'bg-neutral/40 data-[state=open]:animate-in data-[state=open]:fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out fixed inset-0 left-0 top-0 z-50 backdrop-blur-sm',
			className
		)}
		{...props}
	/>
));
NewDialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogContent = cva(
	cn(
		'rounded-md overflow-y-auto flex flex-col text-base-content drop-shadow-xl max-h-[80vh] bg-base-100 w-[90vw] scrollbar pointer-events-auto	'
	),
	{
		variants: {
			width: {
				sm: ['max-w-sm'],
				md: ['max-w-md'],
				lg: ['max-w-lg'],
				xl: ['max-w-xl'],
				'2xl': ['max-w-2xl'],
				'3xl': ['max-w-3xl'],
				'4xl': ['max-w-4xl'],
				'5xl': ['max-w-5xl'],
				'6xl': ['max-w-6xl'],
				'7xl': ['max-w-7xl'],
			},
		},
		defaultVariants: {
			width: 'md',
		},
	}
);

const dialogContentContianer = cva(
	cn(
		'fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen pointer-events-none	',
		'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=open]:fade-in data-[state=closed]:fade-out'
	),
	{
		variants: {
			animate: {
				fromTop: ['data-[state=open]:zoom-in-95 data-[state=closed]:zoom-out-95'],
				fadeIn: [''],
			},
		},
		defaultVariants: {
			animate: 'fromTop',
		},
	}
);

export interface IDialogContentProps
	extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
		VariantProps<typeof dialogContent>,
		VariantProps<typeof dialogContentContianer> {}

const NewDialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, IDialogContentProps>(
	({ className, animate, children, width, ...props }, ref) => (
		<DialogPortal>
			<NewDialogOverlay />
			<DialogPrimitive.Content
				ref={ref}
				{...props}
				className={dialogContentContianer({ animate, className })}
				style={{
					pointerEvents: 'none',
				}}
			>
				<div
					className={dialogContent({ width, className })}
					style={{
						pointerEvents: 'auto',
					}}
				>
					{children}
				</div>
			</DialogPrimitive.Content>
		</DialogPortal>
	)
);
NewDialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
	className,
	children,
	hideCloseIcon,
	...props
}: React.HTMLAttributes<HTMLDivElement> & { hideCloseIcon?: boolean }) => (
	<div
		className={cn('flex p-4 pr-2 pb-2 w-full justify-between sticky top-0 bg-base-100 z-30', className)}
		{...props}
	>
		<div className="flex flex-col w-full gap-2 pt-2">{children}</div>
		{!hideCloseIcon && (
			<DialogPrimitive.Close asChild>
				<Button
					icon={HiXMark}
					size={'sm'}
					shape={'square'}
					variant={'ghost'}
					className="self-starts justify-self-end"
				/>
			</DialogPrimitive.Close>
		)}
	</div>
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn('flex flex-row justify-end gap-2 py-4 mt-6 sticky bottom-0 z-30 px-4 bg-base-100', className)}
		{...props}
	/>
);

const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Title
		ref={ref}
		className={cn('text-lg font-semibold leading-none tracking-tight', className)}
		{...props}
	/>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Description
		ref={ref}
		className={cn('text-sm font-light opacity-70 leading-tight tracking-tight mb-1', className)}
		{...props}
	/>
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogMessage = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn('text-sm font-light px-4 w-3/4', className)} {...props} />
);

const DialogBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div {...props} className={cn('text-sm font-light px-4 py-2 flex flex-col', className)} />
);

const dialogWarning = cva('flex items-center justify-center gap-1 text-xs rounded-md', {
	variants: {
		variant: {
			primary: ['text-primary'],
			secondary: ['text-secondary'],
			neutral: ['text-neutral'],
			success: ['text-success'],
			error: ['text-error'],
			warning: ['text-warning-content'],
			info: ['text-info'],
		},
	},
	defaultVariants: {
		variant: 'warning',
	},
});

export interface IDialogWarningProps
	extends React.ButtonHTMLAttributes<HTMLDivElement>,
		VariantProps<typeof dialogWarning> {
	icon: IconType;
}

const DialogWarning = ({ className, variant, icon: Icon, children, ...props }: IDialogWarningProps) => (
	<div className="flex">
		<div className={cn(dialogWarning({ variant }), className)} {...props}>
			<Icon className="w-3 h-3" />
			{children}
		</div>
	</div>
);

export {
	DialogBody,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogMessage,
	DialogOverlay,
	DialogRoot,
	DialogTitle,
	DialogTrigger,
	DialogWarning,
	NewDialogContent,
	NewDialogOverlay,
};
