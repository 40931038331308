import { UserDataProvider, useUserSettings } from '@slabs/feature-core-ui';
import { LOGIN_URL, QUERY_CLIENT } from '@slabs/ui-api';
import { QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import { Suspense, lazy, useEffect } from 'react';
import Soccerball from '../assets/Soccerball.svg';

dayjs.extend(weekday);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);
dayjs.locale({
	...en,
	weekStart: 1,
});

const Root = lazy(() => import('./root'));

export function App() {
	const { theme } = useUserSettings((state) => ({
		theme: state.theme,
	}));

	useEffect(() => {
		document.querySelector('html')?.setAttribute('data-theme', theme);
	}, [theme]);

	return (
		<Suspense fallback={<FallbackComponent />}>
			<QueryClientProvider client={QUERY_CLIENT}>
				<UserDataProvider loginUrl={LOGIN_URL}>
					<Root />
				</UserDataProvider>
			</QueryClientProvider>
		</Suspense>
	);
}

function FallbackComponent() {
	return (
		<div
			className="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-4 transition-colors bg-base-200"
			style={{
				background: 'radial-gradient(oklch(var(--b1)),oklch(var(--b1)), oklch(var(--p)/0.3))',
			}}
		>
			<div className="animate-spin-ball">
				<img src={Soccerball} style={{ width: '10vw', height: '10vh' }} alt="loader" />
			</div>
			<div className="flex gap-1">
				<span className="text-2xl font-light">Loading...</span>
			</div>
		</div>
	);
}
