import {
	useAuthControllerGetUserInfoSuspense,
	UserGetAllPublicDto,
	useTenantUserControllerGetAllTenantUsersPublic,
} from '@slabs/ui-api';
import { useEffect } from 'react';
import { useUserData } from '../../states';

// This is a simple wrapper just so that we can trigger the upstream suspense
export const UserDataProvider = ({ children, loginUrl }: React.PropsWithChildren & { loginUrl: string }) => {
	const { data, error } = useAuthControllerGetUserInfoSuspense({ query: { retry: 1 } });
	const [setUserInfo, userInfo, setUserMap, setUserArray] = useUserData((state) => [
		state.setUserInfo,
		state.userInfo,
		state.setUserMap,
		state.setUserArray,
	]);

	const { data: { data: users } = {} } = useTenantUserControllerGetAllTenantUsersPublic(
		userInfo.selectedTenant || ''
	);

	useEffect(() => {
		if (users) {
			setUserArray(users ?? []);
			setUserMap(
				users?.reduce(
					(acc, user) => {
						acc[user.id] = user;
						return acc;
					},
					{} as Record<string, UserGetAllPublicDto>
				) ?? {}
			);
		}
	}, [setUserArray, setUserMap, users]);

	useEffect(() => {
		if (error) {
			window.location.href = loginUrl;
		}
	}, [error, loginUrl]);

	useEffect(() => {
		if (data) {
			setUserInfo(data.data);
		}
	}, [data, setUserInfo]);

	return <> {userInfo.id ? children : null}</>;
};
