import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

const root = cva('relative flex w-full touch-none select-none items-center px-0', {
	variants: {
		size: {
			sm: ['input-sm'],
			md: ['input-md'],
		},
	},
	defaultVariants: {
		size: 'md',
	},
});

const track = cva('relative w-full rounded-sm grow cursor-pointer', {
	variants: {
		variant: {
			neutral: ['bg-base-300'],
		},
		size: {
			sm: ['h-1'],
			md: ['h-1.5'],
		},
	},
	defaultVariants: {
		variant: 'neutral',
		size: 'md',
	},
});

const range = cva('absolute ', {
	variants: {
		variant: {
			neutral: ['bg-neutral'],
		},
		size: {
			sm: ['h-1 '],
			md: ['h-1.5'],
		},
	},
	defaultVariants: {
		variant: 'neutral',
		size: 'md',
	},
});

const thumb = cva(
	'block transition-colors outline-none ring-offset-transparent disabled:pointer-events-none disabled:opacity-50 hover:cursor-pointer',
	{
		variants: {
			variant: {
				neutral: ['border-neutral bg-neutral ring-info/50 '],
			},
			size: {
				sm: ['w-3 h-3 rounded-sm focus-visible:ring-1 focus-visible:ring-offset-2'],
				md: ['w-4 h-4 rounded-sm focus-visible:ring-2 focus-visible:ring-offset-2'],
			},
		},
		defaultVariants: {
			variant: 'neutral',
			size: 'md',
		},
	}
);

export type RangeSliderProps = React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> &
	VariantProps<typeof root> &
	VariantProps<typeof thumb> &
	VariantProps<typeof track> &
	VariantProps<typeof range>;

export const RangeSlider = React.forwardRef<React.ElementRef<typeof SliderPrimitive.Root>, RangeSliderProps>(
	({ className, size, variant, ...props }, ref) => (
		<SliderPrimitive.Root ref={ref} {...props} className={root({ size, className })}>
			<SliderPrimitive.Track className={track({ size, variant, className })}>
				<SliderPrimitive.Range className={range({ size, variant, className })} />
			</SliderPrimitive.Track>
			{(props.value || props.defaultValue || [0])?.map((_v, index) => (
				<SliderPrimitive.Thumb className={thumb({ size, variant, className })} key={index} />
			))}
		</SliderPrimitive.Root>
	)
);

RangeSlider.displayName = 'RangeSlider';
