import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { HiArrowRight } from 'react-icons/hi2';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const NextButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={HiArrowRight} variant={'primary'} contrast={'outline'} iconPosition="end" {...props} ref={ref}>
			<Trans i18nKey={'common:labels.next'}>Next {{ value: children || '' }}</Trans>
		</Button>
	);
});

NextButton.displayName = 'NextButton';
