export function downloadAsCSV(data: string) {
	const blob = new Blob([data], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.setAttribute('href', url);
	a.setAttribute('download', 'export.csv');
	a.click();
	a.remove();
}

export async function downloadUrl(url: string, fileName: string) {
	const res = await fetch(url);
	const blob = await res.blob();
	const a = document.createElement('a');
	a.setAttribute('download', fileName);
	a.href = URL.createObjectURL(blob);
	a.click();
}
