import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '../util';

const avatar = cva('relative flex h-10 w-10 shrink-0 overflow-hidden ', {
	variants: {
		mask: {
			circle: ['mask mask-circle'],
			none: [''],
		},
	},
	defaultVariants: {
		mask: 'circle',
	},
});

const Avatar = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & VariantProps<typeof avatar>
>(({ className, mask, ...props }, ref) => (
	<AvatarPrimitive.Root ref={ref} className={avatar({ mask, className })} {...props} />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Image>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
	<AvatarPrimitive.Image ref={ref} className={cn('aspect-square h-full w-full', className)} {...props} />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const avatarFallback = cva('flex h-full w-full items-center justify-center', {
	variants: {
		variant: {
			default: ['bg-base-200 text-base-content'],
		},
	},
	defaultVariants: {
		variant: 'default',
	},
});

const AvatarFallback = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Fallback>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & VariantProps<typeof avatarFallback>
>(({ className, variant, ...props }, ref) => (
	<AvatarPrimitive.Fallback ref={ref} className={avatarFallback({ variant, className })} {...props} />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarFallback, AvatarImage };
