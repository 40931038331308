import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { HiChevronDown, HiXMark } from 'react-icons/hi2';
import { Button } from '../buttons';
import { Icon } from '../images';
import { cn } from '../util';
import { IInputGroupProps, InputGroup } from './InputGroup';

const combobox = cva('relative flex', {
	variants: {
		hidden: {
			true: ['hidden'],
			false: [],
		},
	},
	defaultVariants: {},
});

export const Combobox = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & VariantProps<typeof combobox>
>(({ hidden, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn(combobox({ hidden, className }))}></div>
));

const comboboxMenu = cva(
	'absolute max-h-80 overflow-y-auto z-20 bg-base-100 border shadow-lg p-2 rounded-sm flex flex-col gap-1 scrollbar',
	{
		variants: {
			// TODO: consider tusing top-[calc(100%+0.15rem)]
			offsetSize: {
				none: [''],
				sm: ['top-10'],
				md: ['top-14'],
			},
			hidden: {
				true: ['hidden'],
				false: [],
			},
			wide: {
				true: ['w-full'],
				false: [],
			},
		},
		defaultVariants: {
			offsetSize: 'md',
			hidden: false,
			wide: true,
		},
	}
);

Combobox.displayName = 'Combobox';

export const ComboboxMenu = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & VariantProps<typeof comboboxMenu>
>(({ offsetSize, hidden, className, wide, ...props }, ref) => (
	<div ref={ref} {...props} className={cn(comboboxMenu({ wide, offsetSize, hidden, className }))}></div>
));

const comboboxOption = cva('flex items-center w-full gap-4 py-0.5 px-2 pr-4 cursor-pointer', {
	variants: {
		highlight: {
			true: [''],
			false: [''],
		},
		selected: {
			true: [''],
			false: [''],
		},
		variant: {
			primary: [''],
		},
	},
	compoundVariants: [
		{
			variant: ['primary'],
			highlight: true,
			class: 'bg-primary/20 text-primary ',
		},
		{
			variant: ['primary'],
			selected: true,
			class: 'bg-primary text-primary-content',
		},
	],
	defaultVariants: {
		highlight: false,
		variant: 'primary',
	},
});

ComboboxMenu.displayName = 'ComboboxMenu';

export const ComboboxOption = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & VariantProps<typeof comboboxOption>
>(({ highlight = false, selected = false, variant, ...props }, ref) => (
	<div ref={ref} {...props} className={cn(comboboxOption({ highlight, selected, variant }), props.className)}></div>
));

ComboboxOption.displayName = 'ComboboxOption';

const comboboxButton = cva('flex items-center px-2', {
	variants: {},
	defaultVariants: {},
});

export const ComboboxButton = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> &
		VariantProps<typeof comboboxButton> & { isOpen?: boolean; clear?: () => void; disabled?: boolean }
>(({ isOpen, clear, disabled, ...props }, ref) => (
	<div ref={ref} {...props} className={cn(comboboxButton({}), props.className)}>
		{clear && (
			<Button
				icon={HiXMark}
				hidden={disabled}
				variant={'ghost'}
				size={'xs'}
				shape={'square'}
				iconSettings={{ cssSize: 'sm-1' }}
				onClick={clear}
				disabled={disabled}
				className={cn({ 'opacity-0': disabled })}
			/>
		)}
		<label
			className="flex items-center self-stretch justify-center p-2 pr-1 cursor-pointer"
			htmlFor={props.htmlFor}
		>
			<Icon icon={HiChevronDown} className={cn('transition-all', { 'rotate-180': isOpen })} strokeWidth={1} />
		</label>
	</div>
));

ComboboxButton.displayName = 'ComboboxButton';

export const ComboboxInput = React.forwardRef<
	HTMLInputElement,
	IInputGroupProps & { isOpen?: boolean; clear?: () => void; disabled?: boolean }
>(({ isOpen, clear, disabled, ...props }, ref) => (
	<InputGroup
		endElement={<ComboboxButton isOpen={isOpen} clear={clear} disabled={disabled} />}
		bgColor={'lighter'}
		shadow={false}
		{...props}
		ref={ref}
	/>
));

ComboboxInput.displayName = 'ComboboxInput';
