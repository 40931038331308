import { DialogClose, DialogContent, DialogPortal } from '@radix-ui/react-dialog';
import { enqueueSnackbar } from 'notistack';
import { ReactNode, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiArrowDownOnSquare, HiArrowLeft, HiChevronLeft, HiChevronRight, HiPhoto } from 'react-icons/hi2';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ReactPlayer from 'react-player';
import { Button } from '../buttons';
import { Icon } from '../images';
import { cn, downloadUrl } from '../util';
import { DialogBody, DialogRoot, DialogTrigger, NewDialogOverlay } from './Dialog';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FileData {
	fileName: string;
	url: string | undefined;
}

interface FilePreviewDialogProps<T extends FileData> {
	data: T[];
	children: (files: T[], onClick: (fileIdx: number) => void) => ReactNode | undefined;
}

const imageEndings = ['png', 'jpg', 'gif'];
const videoEndings = ['mp4', 'webm'];

export const FilePreviewDialog = <T extends FileData>({ data, children }: FilePreviewDialogProps<T>) => {
	const [open, setOpen] = useState(false);
	const [currentFileIdx, setCurrentFileIdx] = useState(0);
	const currentFile = useMemo(() => data.at(currentFileIdx), [currentFileIdx, data]);

	const [downloading, setDownloading] = useState(false);
	const [displayError, setDisplayError] = useState(false);

	const [totalPages, setTotalPages] = useState<number | null>(null);
	const { t } = useTranslation(['common']);

	const isImage = useMemo(
		() => imageEndings.includes(currentFile?.fileName.split('.').at(1)?.toLowerCase() || ''),
		[currentFile?.fileName]
	);
	const isVideo = useMemo(
		() => videoEndings.includes(currentFile?.fileName.split('.').at(1)?.toLowerCase() || ''),
		[currentFile?.fileName]
	);
	const isPdf = useMemo(
		() => 'pdf' === currentFile?.fileName.split('.').at(1)?.toLowerCase(),
		[currentFile?.fileName]
	);
	const previewUnavailable = !isPdf && !isVideo && !isImage;

	const downloadFile = async () => {
		if (currentFile?.url) {
			setDownloading(true);
			downloadUrl(currentFile?.url, currentFile?.fileName)
				.catch(() =>
					enqueueSnackbar(
						t('common:files.download.toast.error', {
							defaultValue: `Error downloading file {{value}}`,
							value: currentFile?.fileName,
						}),
						{ variant: 'error' }
					)
				)
				.finally(() => setDownloading(false));
		} else {
			enqueueSnackbar(
				t('common:files.download.toast.error', {
					defaultValue: `Error downloading file {{value}}`,
					value: currentFile?.fileName,
				}),
				{ variant: 'error' }
			);
		}
	};

	const onOpenChange = async (b: boolean) => {
		setOpen(b);
	};

	const prev = () => {
		if (currentFileIdx <= 0) {
			setCurrentFileIdx(data.length - 1);
		} else {
			setCurrentFileIdx((p) => p - 1);
		}
	};

	const next = () => {
		if (currentFileIdx >= data.length - 1) {
			setCurrentFileIdx(0);
		} else {
			setCurrentFileIdx((p) => p + 1);
		}
	};

	const onChildClick = (fileIdx: number) => {
		setCurrentFileIdx(fileIdx);
	};

	return (
		<DialogRoot open={open} onOpenChange={onOpenChange}>
			{data.length > 0 && (
				<DialogTrigger className="w-full" asChild>
					{children(data, onChildClick)}
				</DialogTrigger>
			)}
			<DialogPortal>
				<NewDialogOverlay className="data-[state=open]:duration-0 data-[state=closed]:duration-0" />
				<DialogContent
					className={cn(
						'fixed top-0 pointer-events-none left-0 w-full h-full overflow-y-auto z-50 text-base-content drop-shadow-xl',
						'flex-col flex gap-2 '
					)}
					style={{
						pointerEvents: 'none',
					}}
				>
					<div
						className="flex items-center justify-between gap-2 px-4 py-2 bg-neutral text-neutral-content"
						style={{
							pointerEvents: 'auto',
						}}
					>
						<div className="flex items-center gap-2">
							<DialogClose asChild>
								<Button
									variant={'ghost'}
									icon={HiArrowLeft}
									size={'sm'}
									iconSettings={{
										cssSize: 'sm-2',
									}}
								/>
							</DialogClose>
							<span className="text-lg font-medium">{currentFile?.fileName}</span>
						</div>
						<Button
							icon={HiArrowDownOnSquare}
							size={'sm'}
							title="Download file"
							variant={'ghost'}
							disabled={downloading || !currentFile?.url}
							isLoading={downloading}
							onClick={() => downloadFile()}
							iconSettings={{ cssSize: 'sm-2' }}
						/>
					</div>
					<div className="flex items-center justify-between px-10 grow">
						<Button
							icon={HiChevronLeft}
							variant={'neutral'}
							size={'md'}
							style={{
								pointerEvents: 'auto',
							}}
							iconSettings={{
								cssSize: 'md',
							}}
							className={cn({ 'opacity-0': data.length <= 1 })}
							disabled={data.length <= 1}
							onClick={prev}
						/>

						<div
							className={cn(
								'min-w-[30%] max-w-[80%] bg-base-100 border max-h-3/4 scrollbar overflow-auto flex flex-col'
							)}
							style={{
								pointerEvents: 'auto',
							}}
						>
							<DialogBody className="relative p-1 h-[90vh]">
								{isImage && currentFile?.url && !displayError && (
									<img
										src={currentFile?.url}
										className="object-scale-down object-center max-h-full h-min"
										crossOrigin="anonymous"
										alt="uploaded"
									/>
								)}
								{isVideo && currentFile?.url && !displayError && (
									<ReactPlayer
										url={currentFile?.url}
										onError={() => setDisplayError(true)}
										controls={true}
										config={{
											file: {
												attributes: {
													crossOrigin: 'anonymous',
												},
											},
										}}
									/>
								)}
								{isPdf && currentFile?.url && !displayError && (
									<Document
										file={{ url: currentFile?.url }}
										onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
										className={'max-h-[600px]'}
										onError={() => setDisplayError(true)}
									>
										{Array(totalPages)
											.fill(null)
											.map((_, i) => (
												<Page pageNumber={i + 1} key={i} renderTextLayer={false} />
											))}
									</Document>
								)}
								{previewUnavailable && (
									<span className="flex items-center justify-center gap-2 text-lg">
										<Trans i18nKey={'players:scouting.files.unsupportedPreview'}>
											Preview not supported.
										</Trans>
										<Button
											icon={HiArrowDownOnSquare}
											size={'sm'}
											title="Download file"
											variant={'ghost'}
											disabled={downloading || !currentFile?.url}
											isLoading={downloading}
											onClick={() => downloadFile()}
											iconSettings={{ cssSize: 'sm-2' }}
										/>
									</span>
								)}
								{displayError && (
									<div className="flex flex-col items-center justify-center w-full pb-10 text-2xl font-medium">
										<Icon icon={HiPhoto} className="h-96 w-96" />
										<Trans i18nKey={'common:files.noUrl'}>Oh no! We cannot find this file</Trans>
									</div>
								)}
							</DialogBody>
						</div>
						<Button
							icon={HiChevronRight}
							variant={'neutral'}
							size={'md'}
							style={{
								pointerEvents: 'auto',
							}}
							className={cn({ 'opacity-0': data.length <= 1 })}
							disabled={data.length <= 1}
							iconSettings={{
								cssSize: 'md',
							}}
							onClick={next}
						/>
					</div>
				</DialogContent>
			</DialogPortal>
		</DialogRoot>
	);
};
