import * as SelectPrimitive from '@radix-ui/react-select';
import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { HiChevronDown } from 'react-icons/hi2';
import { Icon } from '../images';
import { cn } from '../util';

const SelectRoot = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;
const selectTrigger = cva('flex items-center justify-between group ', {
	variants: {
		size: {
			none: [],
			xs: ['btn-xs'],
			sm: ['btn-sm'],
		},
		rounded: {
			none: [],
			md: ['rounded-md'],
		},
		outline: {
			false: [],
			true: ['btn-outline'],
		},
		variant: {
			none: [],
			default: ['btn btn-neutral '],
			primary: ['btn btn-primary'],
			secondary: ['btn btn-secondary'],
			neutral: ['btn btn-neutral'],
			ghost: ['text-inherit btn hover:btn-primary border-base-content/20'],
			'base-200': ['hover:bg-base-200 text-base-content flex gap-2 hover:text-base-content'],
			error: ['btn-error btn'],
		},
		border: {
			true: ['border'],
			false: ['border-0'],
		},
	},
	defaultVariants: {
		variant: 'default',
		rounded: 'md',
		outline: true,
		size: 'sm',
		border: true,
	},
});

export type SelectTriggerProps = SelectPrimitive.SelectTriggerProps &
	VariantProps<typeof selectTrigger> & { isError?: boolean };

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
	({ className, children, isError, variant, size, rounded, outline, border, ...props }, ref) => (
		<SelectPrimitive.Trigger
			ref={ref}
			className={selectTrigger({
				className,
				size,
				outline,
				rounded,
				border,
				variant: isError ? 'error' : variant,
			})}
			{...props}
		>
			{children}
			<SelectPrimitive.Icon asChild>
				<Icon
					icon={HiChevronDown}
					cssSize={'sm-1'}
					className=" group-data-[state=open]:rotate-180 transition-all group-disabled:hidden"
				/>
			</SelectPrimitive.Icon>
		</SelectPrimitive.Trigger>
	)
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectIcon = ({ className, ...props }: SelectPrimitive.SelectIconProps) => (
	<SelectPrimitive.Icon className={cn('', className)} {...props}>
		<Icon icon={HiChevronDown} />
	</SelectPrimitive.Icon>
);
SelectIcon.displayName = SelectPrimitive.Icon.displayName;

const SelectContent = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & { triggerWidth?: boolean }
>(({ className, children, position = 'popper', triggerWidth = true, ...props }, ref) => (
	<SelectPrimitive.Portal>
		<SelectPrimitive.Content
			ref={ref}
			{...props}
			className={cn(
				'relative z-50 overflow-hidden bg-base-100 text-base-content rounded-md border shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
				className
			)}
			position={position}
		>
			<SelectPrimitive.Viewport
				className={cn(
					'min-w-[var(--radix-select-trigger-width)]',
					position === 'popper' && triggerWidth && 'w-[var(--radix-select-trigger-width)]'
				)}
			>
				{children}
			</SelectPrimitive.Viewport>
		</SelectPrimitive.Content>
	</SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Label>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
	<SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} {...props} />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const selectItem = cva(
	'flex items-center px-4 py-2 gap-2  select-none text-sm  outline-none  cursor-pointer data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ',
	{
		variants: {
			variant: {
				primary: ['hover:bg-primary  text-primary hover:text-primary-content'],
				secondary: ['hover:bg-secondary  text-secondary hover:text-secondary-content'],
				'base-200': ['hover:bg-base-200 text-base-content hover:text-base-content'],
				none: [],
			},
			highlightChecked: {
				true: [
					'data-[state=checked]:bg-primary data-[state=checked]:text-primary-content focus:bg-primary focus:text-primary-content',
				],
				false: [],
			},
		},
		defaultVariants: {
			highlightChecked: true,
			variant: 'primary',
		},
	}
);

type SelectItemProps = SelectPrimitive.SelectItemProps & VariantProps<typeof selectItem>;

const SelectItem = React.forwardRef<React.ComponentRef<typeof SelectPrimitive.Item>, SelectItemProps>(
	({ className, children, highlightChecked, variant, ...props }, ref) => (
		<SelectPrimitive.Item ref={ref} {...props} className={selectItem({ className, highlightChecked, variant })}>
			<SelectPrimitive.ItemText asChild>{children}</SelectPrimitive.ItemText>
		</SelectPrimitive.Item>
	)
);
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Separator>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
	<SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-muted', className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

type Props<T> = Pick<SelectPrimitive.SelectValueProps, 'placeholder'> &
	Partial<Omit<UseFormRegisterReturn, 'ref'>> &
	VariantProps<typeof selectTrigger> & {
		options: T[] | undefined;
		key?: keyof T;
		option: keyof T;
		value?: string | undefined;
		defaultValue?: string | undefined;
		optionComponent: React.FC<{ data: T }>;
	};

const Select = <T,>({
	placeholder,
	name,
	onChange,
	onBlur,
	options = [],
	key = 'id' as keyof T,
	option,
	optionComponent: Component,
	defaultValue,
	value,
	variant,
	size,
}: Props<T>) => {
	return (
		<SelectRoot
			value={value}
			defaultValue={defaultValue}
			onValueChange={(value) =>
				onChange &&
				onChange({
					target: {
						name,
						value,
					},
				})
			}
		>
			<SelectTrigger variant={variant} size={size} onBlur={onBlur}>
				<SelectValue placeholder={placeholder} />
			</SelectTrigger>
			<SelectContent>
				{options.map((opt) => (
					<SelectItem key={opt[key as keyof T] as React.Key} value={opt[option] as string}>
						<Component data={opt} />
					</SelectItem>
				))}
			</SelectContent>
		</SelectRoot>
	);
};

export {
	Select,
	SelectContent,
	SelectGroup,
	SelectIcon,
	SelectItem,
	SelectLabel,
	SelectRoot,
	SelectSeparator,
	SelectTrigger,
	SelectValue,
};
