import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { cn } from '../util';

const textarea = cva('textarea w-full rounded-md disabled:bg-neutral/20', {
	variants: {
		variant: {
			default: [],
			primary: ['textarea-primary'],
			secondary: ['textarea-secondary'],
			neutral: ['textarea-neutral'],
			success: ['textarea-success'],
			ghost: ['textarea-ghost bg-transparent'],
			error: ['textarea-error'],
			warning: ['textarea-warning'],
			info: ['textarea-info'],
		},
		bgColor: {
			lighter: ['bg-base-100'],
			light: ['bg-base-200'],
			normal: ['bg-base-300'],
		},
		cssSize: {
			xs: ['textarea-xs'],
			sm: ['textarea-sm'],
			md: [''],
			lg: ['textarea-lg'],
		},
		shadow: {
			false: [],
			true: ['shadow-inner'],
		},
		border: {
			false: [],
			true: ['textarea-bordered'],
		},
		outline: {
			false: ['focus:outline-none'],
			true: [],
		},
	},
	defaultVariants: {
		variant: 'default',
		cssSize: 'md',
		bgColor: 'light',
		border: true,
		outline: true,
		shadow: true,
	},
});

export interface ITextareaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		VariantProps<typeof textarea> {
	isError?: boolean;
	isSuccess?: boolean;
	isLoading?: boolean;
	containerConfig?: React.HTMLAttributes<HTMLSpanElement>;
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
	(
		{
			className,
			variant,
			bgColor,
			cssSize,
			isError,
			isSuccess,
			outline,
			isLoading,
			border,
			shadow,
			containerConfig,
			...props
		},
		ref
	) => {
		return (
			<div
				{...containerConfig}
				className={cn(
					'w-full ',
					{
						'relative after:absolute after:loading after:loading-infinity after:right-2 after:top-1/2 after:-translate-y-1/2':
							isLoading,
					},
					containerConfig?.className
				)}
			>
				<textarea
					ref={ref}
					{...props}
					aria-invalid={props['aria-invalid'] || isError}
					data-lpignore="true"
					data-1p-ignore="true"
					className={cn(
						textarea({
							variant: isError ? 'error' : isSuccess ? 'success' : variant,
							cssSize,
							border,
							bgColor,
							outline,
							shadow,
							className,
						})
					)}
					disabled={props.disabled || isLoading}
				/>
			</div>
		);
	}
);

Textarea.displayName = 'Textarea';
