/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Strykerlabs API
 * Strykerlabs API
 * OpenAPI spec version: 0.1
 */

export type ScoutingPlayerTableConfigLayoutDtoColumnsItem =
	(typeof ScoutingPlayerTableConfigLayoutDtoColumnsItem)[keyof typeof ScoutingPlayerTableConfigLayoutDtoColumnsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScoutingPlayerTableConfigLayoutDtoColumnsItem = {
	wy_per_90_accelerations: 'wy_per_90_accelerations',
	wy_per_90_aerial_duels: 'wy_per_90_aerial_duels',
	wy_per_90_aerial_duels_won: 'wy_per_90_aerial_duels_won',
	wy_per_90_assists: 'wy_per_90_assists',
	wy_per_90_attacking_actions: 'wy_per_90_attacking_actions',
	wy_per_90_back_passes: 'wy_per_90_back_passes',
	wy_per_90_clearances: 'wy_per_90_clearances',
	wy_per_90_corners: 'wy_per_90_corners',
	wy_per_90_counterpressing_recoveries: 'wy_per_90_counterpressing_recoveries',
	wy_per_90_crosses: 'wy_per_90_crosses',
	wy_per_90_dangerous_opponent_half_recoveries: 'wy_per_90_dangerous_opponent_half_recoveries',
	wy_per_90_dangerous_own_half_losses: 'wy_per_90_dangerous_own_half_losses',
	wy_per_90_defensive_actions: 'wy_per_90_defensive_actions',
	wy_per_90_defensive_duels: 'wy_per_90_defensive_duels',
	wy_per_90_defensive_duels_won: 'wy_per_90_defensive_duels_won',
	wy_per_90_direct_free_kicks: 'wy_per_90_direct_free_kicks',
	wy_per_90_direct_free_kicks_on_target: 'wy_per_90_direct_free_kicks_on_target',
	wy_per_90_direct_red_cards: 'wy_per_90_direct_red_cards',
	wy_per_90_dribbles: 'wy_per_90_dribbles',
	wy_per_90_dribbles_against: 'wy_per_90_dribbles_against',
	wy_per_90_dribbles_against_won: 'wy_per_90_dribbles_against_won',
	wy_per_90_duels: 'wy_per_90_duels',
	wy_per_90_duels_won: 'wy_per_90_duels_won',
	wy_per_90_field_aerial_duels: 'wy_per_90_field_aerial_duels',
	wy_per_90_field_aerial_duels_won: 'wy_per_90_field_aerial_duels_won',
	wy_per_90_forward_passes: 'wy_per_90_forward_passes',
	wy_per_90_fouls: 'wy_per_90_fouls',
	wy_per_90_fouls_suffered: 'wy_per_90_fouls_suffered',
	wy_per_90_free_kicks: 'wy_per_90_free_kicks',
	wy_per_90_free_kicks_on_target: 'wy_per_90_free_kicks_on_target',
	wy_per_90_gk_aerial_duels: 'wy_per_90_gk_aerial_duels',
	wy_per_90_gk_aerial_duels_won: 'wy_per_90_gk_aerial_duels_won',
	wy_per_90_gk_clean_sheets: 'wy_per_90_gk_clean_sheets',
	wy_per_90_gk_conceded_goals: 'wy_per_90_gk_conceded_goals',
	wy_per_90_gk_exits: 'wy_per_90_gk_exits',
	wy_per_90_gk_saves: 'wy_per_90_gk_saves',
	wy_per_90_gk_shots_against: 'wy_per_90_gk_shots_against',
	wy_per_90_gk_successful_exits: 'wy_per_90_gk_successful_exits',
	wy_per_90_goal_kicks: 'wy_per_90_goal_kicks',
	wy_per_90_goal_kicks_long: 'wy_per_90_goal_kicks_long',
	wy_per_90_goal_kicks_short: 'wy_per_90_goal_kicks_short',
	wy_per_90_goals: 'wy_per_90_goals',
	wy_per_90_head_shots: 'wy_per_90_head_shots',
	wy_per_90_interceptions: 'wy_per_90_interceptions',
	wy_per_90_key_passes: 'wy_per_90_key_passes',
	wy_per_90_lateral_passes: 'wy_per_90_lateral_passes',
	wy_per_90_linkup_plays: 'wy_per_90_linkup_plays',
	wy_per_90_long_passes: 'wy_per_90_long_passes',
	wy_per_90_loose_ball_duels: 'wy_per_90_loose_ball_duels',
	wy_per_90_loose_ball_duels_won: 'wy_per_90_loose_ball_duels_won',
	wy_per_90_losses: 'wy_per_90_losses',
	wy_per_90_missed_balls: 'wy_per_90_missed_balls',
	wy_per_90_new_defensive_duels_won: 'wy_per_90_new_defensive_duels_won',
	wy_per_90_new_duels_won: 'wy_per_90_new_duels_won',
	wy_per_90_new_offensive_duels_won: 'wy_per_90_new_offensive_duels_won',
	wy_per_90_new_successful_dribbles: 'wy_per_90_new_successful_dribbles',
	wy_per_90_offensive_duels: 'wy_per_90_offensive_duels',
	wy_per_90_offensive_duels_won: 'wy_per_90_offensive_duels_won',
	wy_per_90_offsides: 'wy_per_90_offsides',
	wy_per_90_opponent_half_recoveries: 'wy_per_90_opponent_half_recoveries',
	wy_per_90_own_half_losses: 'wy_per_90_own_half_losses',
	wy_per_90_passes: 'wy_per_90_passes',
	wy_per_90_passes_to_final_third: 'wy_per_90_passes_to_final_third',
	wy_per_90_penalties: 'wy_per_90_penalties',
	wy_per_90_pressing_duels: 'wy_per_90_pressing_duels',
	wy_per_90_pressing_duels_won: 'wy_per_90_pressing_duels_won',
	wy_per_90_progressive_passes: 'wy_per_90_progressive_passes',
	wy_per_90_progressive_run: 'wy_per_90_progressive_run',
	wy_per_90_received_pass: 'wy_per_90_received_pass',
	wy_per_90_recoveries: 'wy_per_90_recoveries',
	wy_per_90_red_cards: 'wy_per_90_red_cards',
	wy_per_90_second_assists: 'wy_per_90_second_assists',
	wy_per_90_shot_assists: 'wy_per_90_shot_assists',
	wy_per_90_shot_on_target_assists: 'wy_per_90_shot_on_target_assists',
	wy_per_90_shots: 'wy_per_90_shots',
	wy_per_90_shots_blocked: 'wy_per_90_shots_blocked',
	wy_per_90_shots_on_target: 'wy_per_90_shots_on_target',
	wy_per_90_sliding_tackles: 'wy_per_90_sliding_tackles',
	wy_per_90_smart_passes: 'wy_per_90_smart_passes',
	wy_per_90_successful_attacking_actions: 'wy_per_90_successful_attacking_actions',
	wy_per_90_successful_back_passes: 'wy_per_90_successful_back_passes',
	wy_per_90_successful_crosses: 'wy_per_90_successful_crosses',
	wy_per_90_successful_defensive_action: 'wy_per_90_successful_defensive_action',
	wy_per_90_successful_dribbles: 'wy_per_90_successful_dribbles',
	wy_per_90_successful_forward_passes: 'wy_per_90_successful_forward_passes',
	wy_per_90_successful_goal_kicks: 'wy_per_90_successful_goal_kicks',
	wy_per_90_successful_key_passes: 'wy_per_90_successful_key_passes',
	wy_per_90_successful_lateral_passes: 'wy_per_90_successful_lateral_passes',
	wy_per_90_successful_linkup_plays: 'wy_per_90_successful_linkup_plays',
	wy_per_90_successful_long_passes: 'wy_per_90_successful_long_passes',
	wy_per_90_successful_passes: 'wy_per_90_successful_passes',
	wy_per_90_successful_passes_to_final_third: 'wy_per_90_successful_passes_to_final_third',
	wy_per_90_successful_penalties: 'wy_per_90_successful_penalties',
	wy_per_90_successful_progressive_passes: 'wy_per_90_successful_progressive_passes',
	wy_per_90_successful_sliding_tackles: 'wy_per_90_successful_sliding_tackles',
	wy_per_90_successful_smart_passes: 'wy_per_90_successful_smart_passes',
	wy_per_90_successful_through_passes: 'wy_per_90_successful_through_passes',
	wy_per_90_successful_vertical_passes: 'wy_per_90_successful_vertical_passes',
	wy_per_90_third_assists: 'wy_per_90_third_assists',
	wy_per_90_through_passes: 'wy_per_90_through_passes',
	wy_per_90_touch_in_box: 'wy_per_90_touch_in_box',
	wy_per_90_vertical_passes: 'wy_per_90_vertical_passes',
	wy_per_90_xg_assist: 'wy_per_90_xg_assist',
	wy_per_90_xg_save: 'wy_per_90_xg_save',
	wy_per_90_xg_shot: 'wy_per_90_xg_shot',
	wy_per_90_yellow_cards: 'wy_per_90_yellow_cards',
	wy_percent_aerial_duels_won: 'wy_percent_aerial_duels_won',
	wy_percent_defensive_duels_won: 'wy_percent_defensive_duels_won',
	wy_percent_direct_free_kicks_on_target: 'wy_percent_direct_free_kicks_on_target',
	wy_percent_dribbles_against_won: 'wy_percent_dribbles_against_won',
	wy_percent_duels_won: 'wy_percent_duels_won',
	wy_percent_field_aerial_duels_won: 'wy_percent_field_aerial_duels_won',
	wy_percent_gk_aerial_duels_won: 'wy_percent_gk_aerial_duels_won',
	wy_percent_gk_saves: 'wy_percent_gk_saves',
	wy_percent_gk_successful_exits: 'wy_percent_gk_successful_exits',
	wy_percent_goal_conversion: 'wy_percent_goal_conversion',
	wy_percent_head_shots_on_target: 'wy_percent_head_shots_on_target',
	wy_percent_new_defensive_duels_won: 'wy_percent_new_defensive_duels_won',
	wy_percent_new_duels_won: 'wy_percent_new_duels_won',
	wy_percent_new_offensive_duels_won: 'wy_percent_new_offensive_duels_won',
	wy_percent_new_successful_dribbles: 'wy_percent_new_successful_dribbles',
	wy_percent_offensive_duels_won: 'wy_percent_offensive_duels_won',
	wy_percent_penalties_conversion: 'wy_percent_penalties_conversion',
	wy_percent_shots_on_target: 'wy_percent_shots_on_target',
	wy_percent_successful_back_passes: 'wy_percent_successful_back_passes',
	wy_percent_successful_crosses: 'wy_percent_successful_crosses',
	wy_percent_successful_dribbles: 'wy_percent_successful_dribbles',
	wy_percent_successful_forward_passes: 'wy_percent_successful_forward_passes',
	wy_percent_successful_goal_kicks: 'wy_percent_successful_goal_kicks',
	wy_percent_successful_key_passes: 'wy_percent_successful_key_passes',
	wy_percent_successful_lateral_passes: 'wy_percent_successful_lateral_passes',
	wy_percent_successful_linkup_plays: 'wy_percent_successful_linkup_plays',
	wy_percent_successful_long_passes: 'wy_percent_successful_long_passes',
	wy_percent_successful_passes: 'wy_percent_successful_passes',
	wy_percent_successful_passes_to_final_third: 'wy_percent_successful_passes_to_final_third',
	wy_percent_successful_progressive_passes: 'wy_percent_successful_progressive_passes',
	wy_percent_successful_shot_assists: 'wy_percent_successful_shot_assists',
	wy_percent_successful_sliding_tackles: 'wy_percent_successful_sliding_tackles',
	wy_percent_successful_smart_passes: 'wy_percent_successful_smart_passes',
	wy_percent_successful_through_passes: 'wy_percent_successful_through_passes',
	wy_percent_successful_vertical_passes: 'wy_percent_successful_vertical_passes',
	wy_percent_win: 'wy_percent_win',
	wy_percent_yellow_cards_per_foul: 'wy_percent_yellow_cards_per_foul',
	wy_totals_matches: 'wy_totals_matches',
	wy_totals_matches_coming_off: 'wy_totals_matches_coming_off',
	wy_totals_matches_in_start: 'wy_totals_matches_in_start',
	wy_totals_matches_substituted: 'wy_totals_matches_substituted',
	wy_totals_minutes_on_field: 'wy_totals_minutes_on_field',
	wy_totals_minutes_tagged: 'wy_totals_minutes_tagged',
	sc_per_90_distance: 'sc_per_90_distance',
	sc_per_90_sprinting_distance: 'sc_per_90_sprinting_distance',
	sc_per_90_hsr_distance: 'sc_per_90_hsr_distance',
	sc_per_90_running_distance: 'sc_per_90_running_distance',
	sc_per_90_count_hsr: 'sc_per_90_count_hsr',
	sc_per_90_count_sprint: 'sc_per_90_count_sprint',
	sc_per_90_count_medium_acceleration: 'sc_per_90_count_medium_acceleration',
	sc_per_90_count_medium_deceleration: 'sc_per_90_count_medium_deceleration',
	sc_per_90_count_high_acceleration: 'sc_per_90_count_high_acceleration',
	sc_per_90_count_high_deceleration: 'sc_per_90_count_high_deceleration',
	sc_per_90_distance_tip: 'sc_per_90_distance_tip',
	sc_per_90_sprinting_distance_tip: 'sc_per_90_sprinting_distance_tip',
	sc_per_90_hsr_distance_tip: 'sc_per_90_hsr_distance_tip',
	sc_per_90_running_distance_tip: 'sc_per_90_running_distance_tip',
	sc_per_90_count_hsr_tip: 'sc_per_90_count_hsr_tip',
	sc_per_90_count_sprint_tip: 'sc_per_90_count_sprint_tip',
	sc_per_90_count_medium_acceleration_tip: 'sc_per_90_count_medium_acceleration_tip',
	sc_per_90_count_medium_deceleration_tip: 'sc_per_90_count_medium_deceleration_tip',
	sc_per_90_count_high_acceleration_tip: 'sc_per_90_count_high_acceleration_tip',
	sc_per_90_count_high_deceleration_tip: 'sc_per_90_count_high_deceleration_tip',
	sc_per_90_distance_otip: 'sc_per_90_distance_otip',
	sc_per_90_sprinting_distance_otip: 'sc_per_90_sprinting_distance_otip',
	sc_per_90_hsr_distance_otip: 'sc_per_90_hsr_distance_otip',
	sc_per_90_running_distance_otip: 'sc_per_90_running_distance_otip',
	sc_per_90_count_hsr_otip: 'sc_per_90_count_hsr_otip',
	sc_per_90_count_sprint_otip: 'sc_per_90_count_sprint_otip',
	sc_per_90_count_medium_acceleration_otip: 'sc_per_90_count_medium_acceleration_otip',
	sc_per_90_count_medium_deceleration_otip: 'sc_per_90_count_medium_deceleration_otip',
	sc_per_90_count_high_acceleration_otip: 'sc_per_90_count_high_acceleration_otip',
	sc_per_90_count_high_deceleration_otip: 'sc_per_90_count_high_deceleration_otip',
	sc_totals_psv_99: 'sc_totals_psv_99',
	sc_totals_top_5_psv_99: 'sc_totals_top_5_psv_99',
	sc_totals_minutes_tip: 'sc_totals_minutes_tip',
	sc_totals_minutes_otip: 'sc_totals_minutes_otip',
	sc_totals_minutes: 'sc_totals_minutes',
	sc_totals_matches: 'sc_totals_matches',
	sc_totals_matches_failed: 'sc_totals_matches_failed',
	wy_role_1_code: 'wy_role_1_code',
	wy_role_1_percentage: 'wy_role_1_percentage',
	wy_role_2_code: 'wy_role_2_code',
	wy_role_2_percentage: 'wy_role_2_percentage',
	wy_role_3_code: 'wy_role_3_code',
	wy_role_3_percentage: 'wy_role_3_percentage',
	sl_pos_1: 'sl_pos_1',
	sl_pos_2: 'sl_pos_2',
	sl_pos_3: 'sl_pos_3',
	wy_height: 'wy_height',
	wy_weight: 'wy_weight',
	age: 'age',
	wy_start_date: 'wy_start_date',
	wy_end_date: 'wy_end_date',
	wy_type_transfer: 'wy_type_transfer',
	wy_agencies: 'wy_agencies',
	wy_contract_expiration: 'wy_contract_expiration',
	wy_market_value: 'wy_market_value',
	sl_side: 'sl_side',
} as const;
