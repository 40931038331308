import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { HiCheck } from 'react-icons/hi2';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const AcceptButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={HiCheck} variant={'primary'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.accept'}>Accept {{ value: children || '' }}</Trans>
		</Button>
	);
});

AcceptButton.displayName = 'AcceptButton';
