import { SeasonalStatDistributionObjectDto } from '@slabs/ui-api';
import { Trans } from 'react-i18next';

// eslint-disable-next-line react-refresh/only-export-components
export enum StatType {
	ATTACKING = 'attacking',
	DRIBBLING = 'dribbling',
	SAVE = 'save',
	GK_ACTIONS = 'gk_actions',
	PASSING = 'passing',
	PHYSICAL = 'physica;',
	RECOVERY = 'recovery',
	LOSS = 'loss',
	DUEL = 'duel',
}

export const AttackingStats = [
	'wy_per_90_goals',
	'wy_per_90_assists',
	'wy_per_90_shots',
	'wy_per_90_head_shots',
	'wy_per_90_linkup_plays',
	'wy_per_90_successful_linkup_plays',
	'wy_per_90_shot_assists',
	'wy_per_90_shot_on_target_assists',
	'wy_per_90_xg_shot',
	'wy_per_90_xg_assist',
	'wy_per_90_touch_in_box',
	'wy_per_90_progressive_run',
	'wy_per_90_second_assists',
	'wy_per_90_third_assists',
	'wy_per_90_goal_kicks',
	'wy_per_90_goal_kicks_short',
	'wy_per_90_goal_kicks_long',
	'wy_per_90_shots_on_target',
	'wy_per_90_successful_goal_kicks',
	'wy_percent_shots_on_target',
	'wy_percent_head_shots_on_target',
	'wy_percent_goal_conversion',
	'wy_percent_successful_shot_assists',
	'wy_percent_successful_linkup_plays',
	'wy_percent_successful_goal_kicks',
];

export const DribblingStats = [
	'wy_per_90_dribbles',
	'wy_per_90_new_successful_dribbles',
	'wy_per_90_dribbles_against',
	'wy_per_90_dribbles_against_won',
	'wy_percent_new_successful_dribbles',
	'wy_percent_dribbles_against_won',
];

export const SaveStats = ['wy_per_90_xg_save', 'wy_per_90_gk_saves', 'wy_percent_gk_saves'];
export const GkActionStats = [
	'wy_per_90_gk_clean_sheets',
	'wy_per_90_gk_conceded_goals',
	'wy_per_90_gk_shots_against',
	'wy_per_90_gk_exits',
	'wy_per_90_gk_successful_exits',
	'wy_per_90_gk_aerial_duels',
	'wy_per_90_gk_aerial_duels_won',
	'wy_percent_gk_successful_exits',
	'wy_percent_gk_aerial_duels_won',
];
export const PassingStats = [
	'wy_per_90_passes',
	'wy_per_90_successful_passes',
	'wy_per_90_smart_passes',
	'wy_per_90_successful_smart_passes',
	'wy_per_90_passes_to_final_third',
	'wy_per_90_successful_passes_to_final_third',
	'wy_per_90_crosses',
	'wy_per_90_successful_crosses',
	'wy_per_90_forward_passes',
	'wy_per_90_successful_forward_passes',
	'wy_per_90_back_passes',
	'wy_per_90_successful_back_passes',
	'wy_per_90_through_passes',
	'wy_per_90_successful_through_passes',
	'wy_per_90_key_passes',
	'wy_per_90_successful_key_passes',
	'wy_per_90_vertical_passes',
	'wy_per_90_successful_vertical_passes',
	'wy_per_90_long_passes',
	'wy_per_90_successful_long_passes',
	'wy_per_90_received_pass',
	'wy_per_90_progressive_passes',
	'wy_per_90_successful_progressive_passes',
	'wy_per_90_lateral_passes',
	'wy_per_90_successful_lateral_passes',
	'wy_percent_successful_passes',
	'wy_percent_successful_smart_passes',
	'wy_percent_successful_passes_to_final_third',
	'wy_percent_successful_crosses',
	'wy_percent_successful_forward_passes',
	'wy_percent_successful_back_passes',
	'wy_percent_successful_through_passes',
	'wy_percent_successful_key_passes',
	'wy_percent_successful_vertical_passes',
	'wy_percent_successful_long_passes',
	'wy_percent_successful_progressive_passes',
	'wy_percent_successful_lateral_passes',
];

export const RecoveryStats = [
	'wy_per_90_interceptions',
	'wy_per_90_recoveries',
	'wy_per_90_opponent_half_recoveries',
	'wy_per_90_dangerous_opponent_half_recoveries',
	'wy_per_90_clearances',
	'wy_per_90_shots_blocked',
	'wy_per_90_counterpressing_recoveries',
];

export const LossStats = ['wy_per_90_losses', 'wy_per_90_own_half_losses', 'wy_per_90_dangerous_own_half_losses'];
export const DuelStats = [
	'wy_per_90_duels',
	'wy_per_90_new_duels_won',
	'wy_per_90_defensive_duels',
	'wy_per_90_new_defensive_duels_won',
	'wy_per_90_offensive_duels',
	'wy_per_90_new_offensive_duels_won',
	'wy_per_90_aerial_duels',
	'wy_per_90_aerial_duels_won',
	'wy_per_90_pressing_duels',
	'wy_per_90_pressing_duels_won',
	'wy_per_90_loose_ball_duels',
	'wy_per_90_loose_ball_duels_won',
	'wy_per_90_field_aerial_duels',
	'wy_per_90_field_aerial_duels_won',
	'wy_percent_new_duels_won',
	'wy_percent_new_defensive_duels_won',
	'wy_percent_new_offensive_duels_won',
	'wy_percent_aerial_duels_won',
	'wy_percent_field_aerial_duels_won',
];
export const PhysicalStats = [
	'sc_per_90_distance',
	'sc_per_90_sprinting_distance',
	'sc_per_90_hsr_distance',
	'sc_per_90_running_distance',
	'sc_per_90_count_hsr',
	'sc_per_90_count_sprint',
	'sc_per_90_count_high_acceleration',
	'sc_per_90_count_high_deceleration',
	'sc_per_90_distance_tip',
	'sc_per_90_sprinting_distance_tip',
	'sc_per_90_hsr_distance_tip',
	'sc_per_90_running_distance_tip',
	'sc_per_90_count_hsr_tip',
	'sc_per_90_count_sprint_tip',
	'sc_per_90_count_high_acceleration_tip',
	'sc_per_90_count_high_deceleration_tip',
	'sc_per_90_distance_otip',
	'sc_per_90_sprinting_distance_otip',
	'sc_per_90_hsr_distance_otip',
	'sc_per_90_running_distance_otip',
	'sc_per_90_count_hsr_otip',
	'sc_per_90_count_sprint_otip',
	'sc_per_90_count_high_acceleration_otip',
	'sc_per_90_count_high_deceleration_otip',
	'sc_totals_psv_99',
	'sc_totals_top_5_psv_99',
	'sc_totals_minutes_tip',
	'sc_totals_minutes_otip',
	'sc_totals_minutes',
	'sc_totals_count_match',
	'sc_totals_count_match_failed',
];

export const StatTypeMap = Object.fromEntries([
	...PhysicalStats.map((s) => [s, StatType.PHYSICAL]),
	...SaveStats.map((s) => [s, StatType.SAVE]),
	...AttackingStats.map((s) => [s, StatType.ATTACKING]),
	...DribblingStats.map((s) => [s, StatType.DRIBBLING]),
	...DuelStats.map((s) => [s, StatType.DUEL]),
	...GkActionStats.map((s) => [s, StatType.GK_ACTIONS]),
	...LossStats.map((s) => [s, StatType.LOSS]),
	...PassingStats.map((s) => [s, StatType.PASSING]),
	...RecoveryStats.map((s) => [s, StatType.RECOVERY]),
]);

// eslint-disable-next-line react-refresh/only-export-components
export const groupByStatType = (terms: (keyof SeasonalStatDistributionObjectDto)[]) => {
	const physical: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const save: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const attack: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const dribbling: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const duel: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const gk: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const loss: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const pass: (keyof SeasonalStatDistributionObjectDto)[] = [];
	const recovery: (keyof SeasonalStatDistributionObjectDto)[] = [];

	for (const term of terms) {
		if (PhysicalStats.includes(term)) {
			physical.push(term);
			continue;
		}
		if (SaveStats.includes(term)) {
			save.push(term);
			continue;
		}
		if (AttackingStats.includes(term)) {
			attack.push(term);
			continue;
		}
		if (DribblingStats.includes(term)) {
			dribbling.push(term);
			continue;
		}
		if (DuelStats.includes(term)) {
			duel.push(term);
			continue;
		}
		if (GkActionStats.includes(term)) {
			gk.push(term);
			continue;
		}
		if (LossStats.includes(term)) {
			loss.push(term);
			continue;
		}
		if (PassingStats.includes(term)) {
			pass.push(term);
			continue;
		}
		if (RecoveryStats.includes(term)) {
			recovery.push(term);
			continue;
		}
	}

	return [...physical, ...save, ...attack, ...dribbling, ...duel, ...gk, ...loss, ...pass, ...recovery];
};

export const StatGroupTerm = ({ term }: { term: keyof SeasonalStatDistributionObjectDto }) => {
	const type = StatTypeMap[term];
	return (
		<>
			{type === StatType.ATTACKING && <Trans i18nKey={'scouting:stats.type.attacking.label'}>Attacking</Trans>}
			{type === StatType.PHYSICAL && <Trans i18nKey={'scouting:stats.type.physical.label'}>Physical</Trans>}
			{type === StatType.SAVE && <Trans i18nKey={'scouting:stats.type.save.label'}>Save</Trans>}
			{type === StatType.DRIBBLING && <Trans i18nKey={'scouting:stats.type.dribbling.label'}>Dribbling</Trans>}
			{type === StatType.DUEL && <Trans i18nKey={'scouting:stats.type.duel.label'}>Duel</Trans>}
			{type === StatType.LOSS && <Trans i18nKey={'scouting:stats.type.loss.label'}>Loss</Trans>}
			{type === StatType.PASSING && <Trans i18nKey={'scouting:stats.type.passing.label'}>Passing</Trans>}
			{type === StatType.RECOVERY && <Trans i18nKey={'scouting:stats.type.recovery.label'}>Recovery</Trans>}
			{type === StatType.GK_ACTIONS && (
				<Trans i18nKey={'scouting:stats.type.gkActions.label'}>Goal Keeper Actions</Trans>
			)}
		</>
	);
};
