import { forwardRef } from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa6';
import { Button, IButtonProps } from './Button';

interface ISortButtonProps extends IButtonProps {
	asc?: boolean;
}

export const SortButton = forwardRef<HTMLButtonElement, ISortButtonProps>(({ asc, ...props }, ref) => {
	return (
		<Button
			variant={'ghost'}
			{...props}
			ref={ref}
			justIcon={true}
			className="ml-2"
			size="xs"
			icon={asc !== undefined ? (asc ? FaSortDown : FaSortUp) : FaSort}
		/>
	);
});

SortButton.displayName = 'SortButton';
