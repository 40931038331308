import React, { useCallback, useEffect } from 'react';
import { ChangeHandler } from 'react-hook-form';
import { Input } from './Input';
import { RangeSlider, RangeSliderProps } from './RangeSlider';

export const RangeSliderWithInput = React.forwardRef<
	React.ElementRef<typeof RangeSlider>,
	Omit<RangeSliderProps, 'value'> & { onChange: ChangeHandler; value: number; integer?: boolean }
>(({ onChange, value, integer = true, ...props }, ref) => {
	const rangedNumberValue = useCallback(
		(n: number) => {
			let val = n;
			if (props.max) {
				val = Math.min(props.max, n);
			}
			if (props.min) {
				val = Math.max(props.min, n);
			}
			if (isNaN(val)) {
				return props.min ?? props.max ?? 0;
			}
			return val;
		},
		[props.max, props.min]
	);

	const handleChange = useCallback(
		(newValue: number[] | undefined) => {
			if (!Array.isArray(newValue)) {
				throw 'MinMaxSlider: no array given in handleChange';
			}
			const newVal = newValue.at(0);

			if (onChange) {
				onChange({
					target: {
						name: props.name,
						value: typeof newVal === 'number' ? rangedNumberValue(newVal) : newVal,
					},
				});
			}
		},
		[onChange, props.name, rangedNumberValue]
	);

	useEffect(() => {
		if (!value) {
			onChange({
				target: {
					name: props.name,
					value: props.min,
				},
			});
		}
	}, [onChange, props.min, props.name, value]);

	const stringToAcceptableValue = useCallback(
		(s: string) => {
			if (s === '') {
				return props.min ?? props.max ?? 0;
			}
			const value = integer ? parseInt(s, 10) : parseFloat(s);

			return rangedNumberValue(value);
		},
		[integer, props.max, props.min, rangedNumberValue]
	);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange({
			target: {
				name: props.name,
				value: stringToAcceptableValue(e.currentTarget.value),
			},
		});
	};

	return (
		<div className="flex items-center w-full gap-2">
			<RangeSlider
				{...props}
				onValueChange={handleChange}
				value={[value]}
				ref={ref}
				min={props.min}
				max={props.max}
			/>
			<Input
				containerConfig={{
					className: 'basis-2/12',
				}}
				className="px-0 text-xs text-right"
				cssSize={props.size}
				variant={'ghost'}
				bgColor={'lighter'}
				border={false}
				shadow={false}
				style={{
					WebkitAppearance: 'none',
					MozAppearance: 'textfield',
				}}
				value={value || props.min}
				onChange={onInputChange}
				type="number"
				step={props.step || 'any'}
				disabled={props.disabled}
				max={props.max}
				min={props.min}
			/>
		</div>
	);
});
RangeSliderWithInput.displayName = 'RangeSliderWithInput';
