import { ScoutingPlayerTableConfigLayoutDtoColumnsItem } from '@slabs/ui-api';
import { Trans } from 'react-i18next';

export const Parameters = [...Object.values(ScoutingPlayerTableConfigLayoutDtoColumnsItem), 'height', 'weight', 'foot'];

export type Parameter = (typeof Parameters)[number];

interface Props {
	term: Parameter;
}

export const ParameterLabelTerm = ({ term }: Props) => {
	return (
		<>
			{term === 'height' && <Trans i18nKey={'parameters:height.label'}>Height</Trans>}
			{term === 'weight' && <Trans i18nKey={'parameters:weight.label'}>Weight</Trans>}
			{term === 'foot' && <Trans i18nKey={'parameters:foot.label'}>Foot</Trans>}
			{term === 'wy_per_90_accelerations' && (
				<Trans i18nKey={'parameters:wy_per_90_accelerations.label'}>Accelerations</Trans>
			)}
			{term === 'wy_per_90_aerial_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_aerial_duels.label'}>Aerial Duels</Trans>
			)}
			{term === 'wy_per_90_aerial_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_aerial_duels_won.label'}>Aerial Duels Won</Trans>
			)}
			{term === 'wy_per_90_assists' && <Trans i18nKey={'parameters:wy_per_90_assists.label'}>Assists</Trans>}
			{term === 'wy_per_90_attacking_actions' && (
				<Trans i18nKey={'parameters:wy_per_90_attacking_actions.label'}>Attacking Actions</Trans>
			)}
			{term === 'wy_per_90_back_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_back_passes.label'}>Back Passes</Trans>
			)}
			{term === 'wy_per_90_clearances' && (
				<Trans i18nKey={'parameters:wy_per_90_clearances.label'}>Clearances</Trans>
			)}
			{term === 'wy_per_90_corners' && <Trans i18nKey={'parameters:wy_per_90_corners.label'}>Corners</Trans>}
			{term === 'wy_per_90_counterpressing_recoveries' && (
				<Trans i18nKey={'parameters:wy_per_90_counterpressing_recoveries.label'}>
					Counterpressing Recoveries
				</Trans>
			)}
			{term === 'wy_per_90_crosses' && <Trans i18nKey={'parameters:wy_per_90_crosses.label'}>Crosses</Trans>}
			{term === 'wy_per_90_dangerous_opponent_half_recoveries' && (
				<Trans i18nKey={'parameters:wy_per_90_dangerous_opponent_half_recoveries.label'}>
					Dangerous Opponent Half Recoveries
				</Trans>
			)}
			{term === 'wy_per_90_dangerous_own_half_losses' && (
				<Trans i18nKey={'parameters:wy_per_90_dangerous_own_half_losses.label'}>
					Dangerous Own Half Losses
				</Trans>
			)}
			{term === 'wy_per_90_defensive_actions' && (
				<Trans i18nKey={'parameters:wy_per_90_defensive_actions.label'}>Defensive Actions</Trans>
			)}
			{term === 'wy_per_90_defensive_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_defensive_duels.label'}>Defensive Duels</Trans>
			)}
			{term === 'wy_per_90_defensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_defensive_duels_won.label'}>Defensive Duels Won</Trans>
			)}
			{term === 'wy_per_90_direct_free_kicks' && (
				<Trans i18nKey={'parameters:wy_per_90_direct_free_kicks.label'}>Direct Free Kicks</Trans>
			)}
			{term === 'wy_per_90_direct_free_kicks_on_target' && (
				<Trans i18nKey={'parameters:wy_per_90_direct_free_kicks_on_target.label'}>
					Direct Free Kicks On Target
				</Trans>
			)}
			{term === 'wy_per_90_direct_red_cards' && (
				<Trans i18nKey={'parameters:wy_per_90_direct_red_cards.label'}>Direct Red Cards</Trans>
			)}
			{term === 'wy_per_90_dribbles' && <Trans i18nKey={'parameters:wy_per_90_dribbles.label'}>Dribbles</Trans>}
			{term === 'wy_per_90_dribbles_against' && (
				<Trans i18nKey={'parameters:wy_per_90_dribbles_against.label'}>Dribbles Against</Trans>
			)}
			{term === 'wy_per_90_dribbles_against_won' && (
				<Trans i18nKey={'parameters:wy_per_90_dribbles_against_won.label'}>Dribbles Against Won</Trans>
			)}
			{term === 'wy_per_90_duels' && <Trans i18nKey={'parameters:wy_per_90_duels.label'}>Duels</Trans>}
			{term === 'wy_per_90_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_duels_won.label'}>Duels Won</Trans>
			)}
			{term === 'wy_per_90_field_aerial_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_field_aerial_duels.label'}>Field Aerial Duel</Trans>
			)}
			{term === 'wy_per_90_field_aerial_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_field_aerial_duels_won.label'}>Field Aerial Duels Won</Trans>
			)}
			{term === 'wy_per_90_forward_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_forward_passes.label'}>Forward Passes</Trans>
			)}
			{term === 'wy_per_90_fouls' && <Trans i18nKey={'parameters:wy_per_90_fouls.label'}>Fouls</Trans>}
			{term === 'wy_per_90_fouls_suffered' && (
				<Trans i18nKey={'parameters:wy_per_90_fouls_suffered.label'}>Fouls Suffered</Trans>
			)}
			{term === 'wy_per_90_free_kicks' && (
				<Trans i18nKey={'parameters:wy_per_90_free_kicks.label'}>Free Kicks</Trans>
			)}
			{term === 'wy_per_90_free_kicks_on_target' && (
				<Trans i18nKey={'parameters:wy_per_90_free_kicks_on_target.label'}>Free Kicks On Target</Trans>
			)}
			{term === 'wy_per_90_gk_aerial_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_gk_aerial_duels.label'}>GK Aerial Duels</Trans>
			)}
			{term === 'wy_per_90_gk_aerial_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_gk_aerial_duels_won.label'}>GK Aerial Duels Won</Trans>
			)}
			{term === 'wy_per_90_gk_clean_sheets' && (
				<Trans i18nKey={'parameters:wy_per_90_gk_clean_sheets.label'}>GK Clean Sheets</Trans>
			)}
			{term === 'wy_per_90_gk_conceded_goals' && (
				<Trans i18nKey={'parameters:wy_per_90_gk_conceded_goals.label'}>GK Conceded Goals</Trans>
			)}
			{term === 'wy_per_90_gk_exits' && <Trans i18nKey={'parameters:wy_per_90_gk_exits.label'}>GK Exits</Trans>}
			{term === 'wy_per_90_gk_saves' && <Trans i18nKey={'parameters:wy_per_90_gk_saves.label'}>GK Saves</Trans>}
			{term === 'wy_per_90_gk_shots_against' && (
				<Trans i18nKey={'parameters:wy_per_90_gk_shots_against.label'}>GK Shots Against</Trans>
			)}
			{term === 'wy_per_90_gk_successful_exits' && (
				<Trans i18nKey={'parameters:wy_per_90_gk_successful_exits.label'}>GK Successful Exits</Trans>
			)}
			{term === 'wy_per_90_goal_kicks' && (
				<Trans i18nKey={'parameters:wy_per_90_goal_kicks.label'}>Goal Kicks</Trans>
			)}
			{term === 'wy_per_90_goal_kicks_long' && (
				<Trans i18nKey={'parameters:wy_per_90_goal_kicks_long.label'}>Goal Kicks Long</Trans>
			)}
			{term === 'wy_per_90_goal_kicks_short' && (
				<Trans i18nKey={'parameters:wy_per_90_goal_kicks_short.label'}>Goal Kicks Short</Trans>
			)}
			{term === 'wy_per_90_goals' && <Trans i18nKey={'parameters:wy_per_90_goals.label'}>Goals</Trans>}
			{term === 'wy_per_90_head_shots' && (
				<Trans i18nKey={'parameters:wy_per_90_head_shots.label'}>Head Shots</Trans>
			)}
			{term === 'wy_per_90_interceptions' && (
				<Trans i18nKey={'parameters:wy_per_90_interceptions.label'}>Interceptions</Trans>
			)}
			{term === 'wy_per_90_key_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_key_passes.label'}>Key Passes</Trans>
			)}
			{term === 'wy_per_90_lateral_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_lateral_passes.label'}>Lateral Passes</Trans>
			)}
			{term === 'wy_per_90_linkup_plays' && (
				<Trans i18nKey={'parameters:wy_per_90_linkup_plays.label'}>Linkup Plays</Trans>
			)}
			{term === 'wy_per_90_long_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_long_passes.label'}>Long Passes</Trans>
			)}
			{term === 'wy_per_90_loose_ball_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_loose_ball_duels.label'}>Loose Ball Duels</Trans>
			)}
			{term === 'wy_per_90_loose_ball_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_loose_ball_duels_won.label'}>Loose Ball Duels Won</Trans>
			)}
			{term === 'wy_per_90_losses' && <Trans i18nKey={'parameters:wy_per_90_losses.label'}>Losses</Trans>}
			{term === 'wy_per_90_missed_balls' && (
				<Trans i18nKey={'parameters:wy_per_90_missed_balls.label'}>Missed Balls</Trans>
			)}
			{term === 'wy_per_90_new_defensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_new_defensive_duels_won.label'}>New Defensive Duels Won</Trans>
			)}
			{term === 'wy_per_90_new_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_new_duels_won.label'}>New Duels Won</Trans>
			)}
			{term === 'wy_per_90_new_offensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_new_offensive_duels_won.label'}>New Offensive Duels Won</Trans>
			)}
			{term === 'wy_per_90_new_successful_dribbles' && (
				<Trans i18nKey={'parameters:wy_per_90_new_successful_dribbles.label'}>New Successful Dribbles</Trans>
			)}
			{term === 'wy_per_90_offensive_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_offensive_duels.label'}>Offensive Duels</Trans>
			)}
			{term === 'wy_per_90_offensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_offensive_duels_won.label'}>Offensive Duels Won</Trans>
			)}
			{term === 'wy_per_90_offsides' && <Trans i18nKey={'parameters:wy_per_90_offsides.label'}>Offsides</Trans>}
			{term === 'wy_per_90_opponent_half_recoveries' && (
				<Trans i18nKey={'parameters:wy_per_90_opponent_half_recoveries.label'}>Opponent Half Recoveries</Trans>
			)}
			{term === 'wy_per_90_own_half_losses' && (
				<Trans i18nKey={'parameters:wy_per_90_own_half_losses.label'}>Own Half Losses</Trans>
			)}
			{term === 'wy_per_90_passes' && <Trans i18nKey={'parameters:wy_per_90_passes.label'}>Passes</Trans>}
			{term === 'wy_per_90_passes_to_final_third' && (
				<Trans i18nKey={'parameters:wy_per_90_passes_to_final_third.label'}>Passes To Final Third</Trans>
			)}
			{term === 'wy_per_90_penalties' && (
				<Trans i18nKey={'parameters:wy_per_90_penalties.label'}>Penalties</Trans>
			)}
			{term === 'wy_per_90_pressing_duels' && (
				<Trans i18nKey={'parameters:wy_per_90_pressing_duels.label'}>Pressing Duels</Trans>
			)}
			{term === 'wy_per_90_pressing_duels_won' && (
				<Trans i18nKey={'parameters:wy_per_90_pressing_duels_won.label'}>Pressing Duels Won</Trans>
			)}
			{term === 'wy_per_90_progressive_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_progressive_passes.label'}>Progressive Passes</Trans>
			)}
			{term === 'wy_per_90_progressive_run' && (
				<Trans i18nKey={'parameters:wy_per_90_progressive_run.label'}>Progressive Run</Trans>
			)}
			{term === 'wy_per_90_received_pass' && (
				<Trans i18nKey={'parameters:wy_per_90_received_pass.label'}>Received Pass</Trans>
			)}
			{term === 'wy_per_90_recoveries' && (
				<Trans i18nKey={'parameters:wy_per_90_recoveries.label'}>Recoveries</Trans>
			)}
			{term === 'wy_per_90_red_cards' && (
				<Trans i18nKey={'parameters:wy_per_90_red_cards.label'}>Red Cards</Trans>
			)}
			{term === 'wy_per_90_second_assists' && (
				<Trans i18nKey={'parameters:wy_per_90_second_assists.label'}>Second Assists</Trans>
			)}
			{term === 'wy_per_90_shot_assists' && (
				<Trans i18nKey={'parameters:wy_per_90_shot_assists.label'}>Shot Assists</Trans>
			)}
			{term === 'wy_per_90_shot_on_target_assists' && (
				<Trans i18nKey={'parameters:wy_per_90_shot_on_target_assists.label'}>Shot On Target Assists</Trans>
			)}
			{term === 'wy_per_90_shots' && <Trans i18nKey={'parameters:wy_per_90_shots.label'}>Shots</Trans>}
			{term === 'wy_per_90_shots_blocked' && (
				<Trans i18nKey={'parameters:wy_per_90_shots_blocked.label'}>Shots Blocked</Trans>
			)}
			{term === 'wy_per_90_shots_on_target' && (
				<Trans i18nKey={'parameters:wy_per_90_shots_on_target.label'}>Shots On Target</Trans>
			)}
			{term === 'wy_per_90_sliding_tackles' && (
				<Trans i18nKey={'parameters:wy_per_90_sliding_tackles.label'}>Sliding Tackles</Trans>
			)}
			{term === 'wy_per_90_smart_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_smart_passes.label'}>Smart Passes</Trans>
			)}
			{term === 'wy_per_90_successful_attacking_actions' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_attacking_actions.label'}>
					Successful Attacking Actions
				</Trans>
			)}
			{term === 'wy_per_90_successful_back_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_back_passes.label'}>Successful Back Passes</Trans>
			)}
			{term === 'wy_per_90_successful_crosses' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_crosses.label'}>Successful Crosses</Trans>
			)}
			{term === 'wy_per_90_successful_defensive_action' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_defensive_action.label'}>
					Successful Defensive Action
				</Trans>
			)}
			{term === 'wy_per_90_successful_dribbles' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_dribbles.label'}>Successful Dribbles</Trans>
			)}
			{term === 'wy_per_90_successful_forward_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_forward_passes.label'}>
					Successful Forward Passes
				</Trans>
			)}
			{term === 'wy_per_90_successful_goal_kicks' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_goal_kicks.label'}>Successful Goal Kicks</Trans>
			)}
			{term === 'wy_per_90_successful_key_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_key_passes.label'}>Successful Key Passes</Trans>
			)}
			{term === 'wy_per_90_successful_lateral_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_lateral_passes.label'}>
					Successful Lateral Passes
				</Trans>
			)}
			{term === 'wy_per_90_successful_linkup_plays' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_linkup_plays.label'}>Successful Linkup Plays</Trans>
			)}
			{term === 'wy_per_90_successful_long_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_long_passes.label'}>Successful Long Passes</Trans>
			)}
			{term === 'wy_per_90_successful_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_passes.label'}>Successful Passes</Trans>
			)}
			{term === 'wy_per_90_successful_passes_to_final_third' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_passes_to_final_third.label'}>
					Successful Passes To Final Third
				</Trans>
			)}
			{term === 'wy_per_90_successful_penalties' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_penalties.label'}>Successful Penalties</Trans>
			)}
			{term === 'wy_per_90_successful_progressive_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_progressive_passes.label'}>
					Successful Progressive Passes
				</Trans>
			)}
			{term === 'wy_per_90_successful_sliding_tackles' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_sliding_tackles.label'}>
					Successful Sliding Tackles
				</Trans>
			)}
			{term === 'wy_per_90_successful_smart_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_smart_passes.label'}>Successful Smart Passes</Trans>
			)}
			{term === 'wy_per_90_successful_through_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_through_passes.label'}>
					Successful Through Passes
				</Trans>
			)}
			{term === 'wy_per_90_successful_vertical_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_successful_vertical_passes.label'}>
					Successful Vertical Passes
				</Trans>
			)}
			{term === 'wy_per_90_third_assists' && (
				<Trans i18nKey={'parameters:wy_per_90_third_assists.label'}>Third Assists</Trans>
			)}
			{term === 'wy_per_90_through_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_through_passes.label'}>Through Passes</Trans>
			)}
			{term === 'wy_per_90_touch_in_box' && (
				<Trans i18nKey={'parameters:wy_per_90_touch_in_box.label'}>Touch In Box</Trans>
			)}
			{term === 'wy_per_90_vertical_passes' && (
				<Trans i18nKey={'parameters:wy_per_90_vertical_passes.label'}>Vertical Passes</Trans>
			)}
			{term === 'wy_per_90_xg_assist' && (
				<Trans i18nKey={'parameters:wy_per_90_xg_assist.label'}>xG Assist</Trans>
			)}
			{term === 'wy_per_90_xg_save' && <Trans i18nKey={'parameters:wy_per_90_xg_save.label'}>xG Save</Trans>}
			{term === 'wy_per_90_xg_shot' && <Trans i18nKey={'parameters:wy_per_90_xg_shot.label'}>xG Shot</Trans>}
			{term === 'wy_per_90_yellow_cards' && (
				<Trans i18nKey={'parameters:wy_per_90_yellow_cards.label'}>Yellow Cards</Trans>
			)}
			{term === 'wy_percent_aerial_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_aerial_duels_won.label'}>% Aerial Duels Won</Trans>
			)}
			{term === 'wy_percent_defensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_defensive_duels_won.label'}>% Defensive Duels Won</Trans>
			)}
			{term === 'wy_percent_direct_free_kicks_on_target' && (
				<Trans i18nKey={'parameters:wy_percent_direct_free_kicks_on_target.label'}>
					% Direct Free Kicks On Target
				</Trans>
			)}
			{term === 'wy_percent_dribbles_against_won' && (
				<Trans i18nKey={'parameters:wy_percent_dribbles_against_won.label'}>% Dribbles Against Won</Trans>
			)}
			{term === 'wy_percent_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_duels_won.label'}>% Duels Won</Trans>
			)}
			{term === 'wy_percent_field_aerial_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_field_aerial_duels_won.label'}>% Field Aerial Duels Won</Trans>
			)}
			{term === 'wy_percent_gk_aerial_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_gk_aerial_duels_won.label'}>% GK Aerial Duels Won</Trans>
			)}
			{term === 'wy_percent_gk_saves' && (
				<Trans i18nKey={'parameters:wy_percent_gk_saves.label'}>% GK Saves</Trans>
			)}
			{term === 'wy_percent_gk_successful_exits' && (
				<Trans i18nKey={'parameters:wy_percent_gk_successful_exits.label'}>% GK Successful Exits</Trans>
			)}
			{term === 'wy_percent_goal_conversion' && (
				<Trans i18nKey={'parameters:wy_percent_goal_conversion.label'}>% Goal Conversion</Trans>
			)}
			{term === 'wy_percent_head_shots_on_target' && (
				<Trans i18nKey={'parameters:wy_percent_head_shots_on_target.label'}>% Head Shots On Target</Trans>
			)}
			{term === 'wy_percent_new_defensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_new_defensive_duels_won.label'}>% New Defensive Duels Won</Trans>
			)}
			{term === 'wy_percent_new_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_new_duels_won.label'}>% New Duels Won</Trans>
			)}
			{term === 'wy_percent_new_offensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_new_offensive_duels_won.label'}>% New Offensive Duels Won</Trans>
			)}
			{term === 'wy_percent_new_successful_dribbles' && (
				<Trans i18nKey={'parameters:wy_percent_new_successful_dribbles.label'}>% New Successful Dribbles</Trans>
			)}
			{term === 'wy_percent_offensive_duels_won' && (
				<Trans i18nKey={'parameters:wy_percent_offensive_duels_won.label'}>% Offensive Duels Won</Trans>
			)}
			{term === 'wy_percent_penalties_conversion' && (
				<Trans i18nKey={'parameters:wy_percent_penalties_conversion.label'}>% Penalties Conversion</Trans>
			)}
			{term === 'wy_percent_shots_on_target' && (
				<Trans i18nKey={'parameters:wy_percent_shots_on_target.label'}>% Shots On Target</Trans>
			)}
			{term === 'wy_percent_successful_back_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_back_passes.label'}>% Successful Back Passes</Trans>
			)}
			{term === 'wy_percent_successful_crosses' && (
				<Trans i18nKey={'parameters:wy_percent_successful_crosses.label'}>% Successful Crosses</Trans>
			)}
			{term === 'wy_percent_successful_dribbles' && (
				<Trans i18nKey={'parameters:wy_percent_successful_dribbles.label'}>% Successful Dribbles</Trans>
			)}
			{term === 'wy_percent_successful_forward_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_forward_passes.label'}>
					% Successful Forward Passes
				</Trans>
			)}
			{term === 'wy_percent_successful_goal_kicks' && (
				<Trans i18nKey={'parameters:wy_percent_successful_goal_kicks.label'}>% Successful Goal Kicks</Trans>
			)}
			{term === 'wy_percent_successful_key_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_key_passes.label'}>% Successful Key Passes</Trans>
			)}
			{term === 'wy_percent_successful_lateral_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_lateral_passes.label'}>
					% Successful Lateral Passes
				</Trans>
			)}
			{term === 'wy_percent_successful_linkup_plays' && (
				<Trans i18nKey={'parameters:wy_percent_successful_linkup_plays.label'}>% Successful Linkup Plays</Trans>
			)}
			{term === 'wy_percent_successful_long_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_long_passes.label'}>% Successful Long Passes</Trans>
			)}
			{term === 'wy_percent_successful_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_passes.label'}>% Successful Passes</Trans>
			)}
			{term === 'wy_percent_successful_passes_to_final_third' && (
				<Trans i18nKey={'parameters:wy_percent_successful_passes_to_final_third.label'}>
					% Successful Passes To Final Third
				</Trans>
			)}
			{term === 'wy_percent_successful_progressive_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_progressive_passes.label'}>
					% Successful Progressive Passes
				</Trans>
			)}
			{term === 'wy_percent_successful_shot_assists' && (
				<Trans i18nKey={'parameters:wy_percent_successful_shot_assists.label'}>% Successful Shot Assists</Trans>
			)}
			{term === 'wy_percent_successful_sliding_tackles' && (
				<Trans i18nKey={'parameters:wy_percent_successful_sliding_tackles.label'}>
					% Successful Sliding Tackles
				</Trans>
			)}
			{term === 'wy_percent_successful_smart_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_smart_passes.label'}>% Successful Smart Passes</Trans>
			)}
			{term === 'wy_percent_successful_through_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_through_passes.label'}>
					% Successful Through Passes
				</Trans>
			)}
			{term === 'wy_percent_successful_vertical_passes' && (
				<Trans i18nKey={'parameters:wy_percent_successful_vertical_passes.label'}>
					% Successful Vertical Passes
				</Trans>
			)}
			{term === 'wy_percent_win' && <Trans i18nKey={'parameters:wy_percent_win.label'}>% Win</Trans>}
			{term === 'wy_percent_yellow_cards_per_foul' && (
				<Trans i18nKey={'parameters:wy_percent_yellow_cards_per_foul.label'}>% Yellow Cards Per Foul</Trans>
			)}
			{term === 'wy_totals_matches' && <Trans i18nKey={'parameters:wy_totals_matches.label'}>Matches</Trans>}
			{term === 'wy_totals_matches_coming_off' && (
				<Trans i18nKey={'parameters:wy_totals_matches_coming_off.label'}>Matches Coming Off</Trans>
			)}
			{term === 'wy_totals_matches_in_start' && (
				<Trans i18nKey={'parameters:wy_totals_matches_in_start.label'}>Matches In Start</Trans>
			)}
			{term === 'wy_totals_matches_substituted' && (
				<Trans i18nKey={'parameters:wy_totals_matches_substituted.label'}>Matches Substituted</Trans>
			)}
			{term === 'wy_totals_minutes_on_field' && (
				<Trans i18nKey={'parameters:wy_totals_minutes_on_field.label'}>Minutes On Field</Trans>
			)}
			{term === 'wy_totals_minutes_tagged' && (
				<Trans i18nKey={'parameters:wy_totals_minutes_tagged.label'}>Minutes Tagged</Trans>
			)}
			{term === 'sc_per_90_distance' && <Trans i18nKey={'parameters:sc_per_90_distance.label'}>Distance</Trans>}
			{term === 'sc_per_90_sprinting_distance' && (
				<Trans i18nKey={'parameters:sc_per_90_sprinting_distance.label'}>Sprinting Distance</Trans>
			)}
			{term === 'sc_per_90_hsr_distance' && (
				<Trans i18nKey={'parameters:sc_per_90_hsr_distance.label'}>HSR Distance</Trans>
			)}
			{term === 'sc_per_90_running_distance' && (
				<Trans i18nKey={'parameters:sc_per_90_running_distance.label'}>Running Distance</Trans>
			)}
			{term === 'sc_per_90_count_hsr' && (
				<Trans i18nKey={'parameters:sc_per_90_count_hsr.label'}>Count HSR</Trans>
			)}
			{term === 'sc_per_90_count_sprint' && (
				<Trans i18nKey={'parameters:sc_per_90_count_sprint.label'}>Count Sprint</Trans>
			)}
			{term === 'sc_per_90_count_medium_acceleration' && (
				<Trans i18nKey={'parameters:sc_per_90_count_medium_acceleration.label'}>
					Count Medium Acceleration
				</Trans>
			)}
			{term === 'sc_per_90_count_medium_deceleration' && (
				<Trans i18nKey={'parameters:sc_per_90_count_medium_deceleration.label'}>
					Count Medium Deceleration
				</Trans>
			)}
			{term === 'sc_per_90_count_high_acceleration' && (
				<Trans i18nKey={'parameters:sc_per_90_count_high_acceleration.label'}>Count High Acceleration</Trans>
			)}
			{term === 'sc_per_90_count_high_deceleration' && (
				<Trans i18nKey={'parameters:sc_per_90_count_high_deceleration.label'}>Count High Deceleration</Trans>
			)}
			{term === 'sc_per_90_distance_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_distance_tip.label'}>Distance TIP</Trans>
			)}
			{term === 'sc_per_90_sprinting_distance_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_sprinting_distance_tip.label'}>Sprinting Distance TIP</Trans>
			)}
			{term === 'sc_per_90_hsr_distance_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_hsr_distance_tip.label'}>HSR Distance TIP</Trans>
			)}
			{term === 'sc_per_90_running_distance_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_running_distance_tip.label'}>Running Distance TIP</Trans>
			)}
			{term === 'sc_per_90_count_hsr_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_hsr_tip.label'}>Count HSR TIP</Trans>
			)}
			{term === 'sc_per_90_count_sprint_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_sprint_tip.label'}>Count Sprint TIP</Trans>
			)}
			{term === 'sc_per_90_count_medium_acceleration_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_medium_acceleration_tip.label'}>
					Count Medium Acceleration TIP
				</Trans>
			)}
			{term === 'sc_per_90_count_medium_deceleration_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_medium_deceleration_tip.label'}>
					Count Medium Deceleration TIP
				</Trans>
			)}
			{term === 'sc_per_90_count_high_acceleration_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_high_acceleration_tip.label'}>
					Count High Acceleration TIP
				</Trans>
			)}
			{term === 'sc_per_90_count_high_deceleration_tip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_high_deceleration_tip.label'}>
					Count High Deceleration TIP
				</Trans>
			)}
			{term === 'sc_per_90_distance_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_distance_otip.label'}>Distance OTIP</Trans>
			)}
			{term === 'sc_per_90_sprinting_distance_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_sprinting_distance_otip.label'}>Sprinting Distance OTIP</Trans>
			)}
			{term === 'sc_per_90_hsr_distance_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_hsr_distance_otip.label'}>HSR Distance OTIP</Trans>
			)}
			{term === 'sc_per_90_running_distance_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_running_distance_otip.label'}>Running Distance OTIP</Trans>
			)}
			{term === 'sc_per_90_count_hsr_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_hsr_otip.label'}>Count HSR OTIP</Trans>
			)}
			{term === 'sc_per_90_count_sprint_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_sprint_otip.label'}>Count Sprint OTIP</Trans>
			)}
			{term === 'sc_per_90_count_medium_acceleration_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_medium_acceleration_otip.label'}>
					Count Medium Acceleration OTIP
				</Trans>
			)}
			{term === 'sc_per_90_count_medium_deceleration_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_medium_deceleration_otip.label'}>
					Count Medium Deceleration OTIP
				</Trans>
			)}
			{term === 'sc_per_90_count_high_acceleration_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_high_acceleration_otip.label'}>
					Count High Acceleration OTIP
				</Trans>
			)}
			{term === 'sc_per_90_count_high_deceleration_otip' && (
				<Trans i18nKey={'parameters:sc_per_90_count_high_deceleration_otip.label'}>
					Count High Deceleration OTIP
				</Trans>
			)}
			{term === 'sc_totals_psv_99' && <Trans i18nKey={'parameters:sc_totals_psv_99.label'}>PSV 99</Trans>}
			{term === 'sc_totals_top_5_psv_99' && (
				<Trans i18nKey={'parameters:sc_totals_top_5_psv_99.label'}>Top 5 PSV 99</Trans>
			)}
			{term === 'sc_totals_minutes_tip' && (
				<Trans i18nKey={'parameters:sc_totals_minutes_tip.label'}>Minutes TIP</Trans>
			)}
			{term === 'sc_totals_minutes_otip' && (
				<Trans i18nKey={'parameters:sc_totals_minutes_otip.label'}>Minutes OTIP</Trans>
			)}
			{term === 'sc_totals_minutes' && <Trans i18nKey={'parameters:sc_totals_minutes.label'}>Minutes</Trans>}
			{term === 'sc_totals_matches' && <Trans i18nKey={'parameters:sc_totals_matches.label'}>Matches</Trans>}
			{term === 'sc_totals_matches_failed' && (
				<Trans i18nKey={'parameters:sc_totals_matches_failed.label'}>Matches Failed</Trans>
			)}
			{term === 'wy_role_1_code' && <Trans i18nKey={'parameters:wy_role_1_code.label'}>Role 1</Trans>}
			{term === 'wy_role_1_percentage' && (
				<Trans i18nKey={'parameters:wy_role_1_percentage.label'}>Role 1 %</Trans>
			)}
			{term === 'wy_role_2_code' && <Trans i18nKey={'parameters:wy_role_2_code.label'}>Role 2</Trans>}
			{term === 'wy_role_2_percentage' && (
				<Trans i18nKey={'parameters:wy_role_2_percentage.label'}>Role 2 %</Trans>
			)}
			{term === 'wy_role_3_code' && <Trans i18nKey={'parameters:wy_role_3_code.label'}>Role 3</Trans>}
			{term === 'wy_role_3_percentage' && (
				<Trans i18nKey={'parameters:wy_role_3_percentage.label'}>Role 3 %</Trans>
			)}
			{term === 'sl_pos_1' && <Trans i18nKey={'parameters:sl_pos_1.label'}>Position 1</Trans>}
			{term === 'sl_pos_2' && <Trans i18nKey={'parameters:sl_pos_2.label'}>Position 2</Trans>}
			{term === 'sl_pos_3' && <Trans i18nKey={'parameters:sl_pos_3.label'}>Position 3</Trans>}
			{term === 'wy_height' && <Trans i18nKey={'parameters:wy_height.label'}>Height</Trans>}
			{term === 'wy_weight' && <Trans i18nKey={'parameters:wy_weight.label'}>Weight</Trans>}
			{term === 'age' && <Trans i18nKey={'parameters:age.label'}>Age</Trans>}
			{term === 'wy_start_date' && <Trans i18nKey={'parameters:wy_start_date.label'}>Contract Start Date</Trans>}
			{term === 'wy_end_date' && <Trans i18nKey={'parameters:wy_end_date.label'}>Contract End Date</Trans>}
			{term === 'wy_type_transfer' && <Trans i18nKey={'parameters:wy_type_transfer.label'}>Transfer Type</Trans>}
			{term === 'wy_agencies' && <Trans i18nKey={'parameters:wy_agencies.label'}>Agencies</Trans>}
			{term === 'wy_contract_expiration' && (
				<Trans i18nKey={'parameters:wy_contract_expiration.label'}>Contract Expiration</Trans>
			)}
			{term === 'wy_market_value' && <Trans i18nKey={'parameters:wy_market_value.label'}>Market Value</Trans>}
			{term === 'sl_side' && <Trans i18nKey={'parameters:sl_side.label'}>Side</Trans>}
		</>
	);
};
