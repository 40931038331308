import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { cn } from '../util';

const input = cva('input w-full disabled:bg-neutral/20', {
	variants: {
		variant: {
			default: [],
			primary: ['input-primary'],
			secondary: ['input-secondary'],
			neutral: ['input-neutral'],
			success: ['input-success'],
			ghost: ['input-ghost bg-transparent'],
			error: ['input-error'],
			warning: ['input-warning'],
			info: ['input-info'],
		},
		rounded: {
			md: ['rounded-md'],
			sm: ['rounded-sm'],
			xs: ['rounded-xs'],
			lg: ['rounded-lg'],
		},
		bgColor: {
			transparent: ['bg-transparent'],
			lighter: ['bg-base-100'],
			light: ['bg-base-200'],
			normal: ['bg-base-300'],
			neutral: ['bg-neutral text-neutral-content'],
		},
		cssSize: {
			xs: ['input-xs'],
			sm: ['input-sm'],
			md: [''],
			lg: ['input-lg'],
		},
		shadow: {
			false: [],
			true: ['shadow-inner'],
		},
		border: {
			false: ['focus:!border-0'],
			true: ['input-bordered'],
		},
		outline: {
			false: ['focus:!outline-none'],
			true: [],
		},
	},
	defaultVariants: {
		variant: 'default',
		cssSize: 'md',
		bgColor: 'transparent',
		border: true,
		outline: true,
		rounded: 'md',
		shadow: true,
	},
});

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof input> {
	isError?: boolean;
	isSuccess?: boolean;
	isLoading?: boolean;
	containerConfig?: React.HTMLAttributes<HTMLSpanElement>;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
	(
		{
			className,
			variant,
			bgColor,
			cssSize,
			isError,
			isSuccess,
			outline,
			isLoading,
			border,
			shadow,
			rounded,
			containerConfig,
			...props
		},
		ref
	) => {
		return (
			<span
				{...containerConfig}
				className={cn(
					'w-full',
					{
						'relative after:absolute after:loading after:loading-infinity after:right-2 after:top-1/2 after:-translate-y-1/2':
							isLoading,
					},
					containerConfig?.className
				)}
			>
				<input
					ref={ref}
					{...props}
					aria-invalid={props['aria-invalid'] || isError}
					data-lpignore="true"
					data-1p-ignore="true"
					className={cn(
						input({
							rounded,
							variant: isError ? 'error' : isSuccess ? 'success' : variant,
							cssSize,
							border,
							bgColor,
							outline,
							shadow,
							className,
						})
					)}
					disabled={props.disabled}
				/>
			</span>
		);
	}
);

Input.displayName = 'Input';
