import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { FaDownload } from 'react-icons/fa6';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const ExportToCsvButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={FaDownload} variant={'ghost'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.exportCSV'}>Export to csv {{ value: children || '' }}</Trans>
		</Button>
	);
});

ExportToCsvButton.displayName = 'ExportToCsvButton';
