import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { IconType } from 'react-icons/lib';
import { Icon, IIconProps } from '../images/Icon';
import { Loader } from '../loaders/Loader';
import { cn } from '../util';

const button = cva('btn disabled:cursor-not-allowed flex-nowrap', {
	variants: {
		variant: {
			default: [],
			primary: ['btn-primary'],
			accent: ['btn-accent'],
			secondary: ['btn-secondary'],
			neutral: ['btn-neutral'],
			success: ['btn-success  '],
			ghost: ['btn-ghost'],
			error: ['btn-error'],
			warning: ['btn-warning'],
			info: ['btn-info'],
		},
		wide: {
			true: ['w-full'],
			false: [],
		},
		size: {
			xs: ['btn-xs'],
			sm: ['btn-sm'],
			md: [''],
			lg: ['btn-lg'],
		},
		shape: {
			circle: ['btn-circle'],
			square: ['btn-square'],
		},
		rounded: {
			sm: ['rounded-sm'],
			md: ['rounded-md'],
			inherit: [],
			lg: ['rounded-lg'],
			xl: ['rounded-xl'],
		},
		contrast: {
			outline: ['btn-outline'],
			noBorder: ['btn-outline border-0'],
		},
	},
	defaultVariants: {
		variant: 'default',
		size: 'sm',
		rounded: 'inherit',
	},
});

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof button> {
	isError?: boolean;
	isSuccess?: boolean;
	isLoading?: boolean;
	icon?: IconType;
	iconPosition?: 'end' | 'start';
	justIcon?: boolean;
	iconSettings?: Omit<IIconProps, 'icon'>;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
	(
		{
			className,
			variant,
			size,
			isError,
			isSuccess,
			isLoading,
			iconPosition = 'start',
			children,
			icon,
			justIcon = !children,
			shape,
			contrast,
			iconSettings = {},
			rounded,
			wide,
			...props
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				type="button"
				{...props}
				className={cn(
					button({
						variant: isError ? 'error' : isSuccess ? 'success' : variant,
						size,
						shape: shape ? shape : justIcon ? 'circle' : undefined,
						contrast,
						rounded,
						wide,
						className,
					})
				)}
				disabled={props.disabled || isLoading}
			>
				{icon && isLoading && iconPosition === 'start' ? (
					<Loader variant={'ring'} />
				) : (
					icon && iconPosition === 'start' && <Icon {...iconSettings} icon={icon} />
				)}

				{(isLoading && icon) || (isLoading && !justIcon) || !isLoading ? justIcon || children : <Loader />}

				{icon && isLoading && iconPosition === 'end' ? (
					<Loader variant={'ring'} />
				) : (
					icon && iconPosition === 'end' && <Icon {...iconSettings} icon={icon} />
				)}
			</button>
		);
	}
);
Button.displayName = 'Button';
