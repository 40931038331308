import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { HiPlus } from 'react-icons/hi2';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const AddButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={HiPlus} variant={'neutral'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.add'}>Add {{ value: children || '' }}</Trans>
		</Button>
	);
});

AddButton.displayName = 'AddButton';
