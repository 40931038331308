import { QueryClient, keepPreviousData } from '@tanstack/react-query';
import { default as Axios, default as axios } from 'axios';

export const API_BASE_URL =
	process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : 'https://api.strykerlabsnova.com/';

export const LOGIN_URL = `${API_BASE_URL}v1/auth/oidc/login?redirectAfterLoginUrl=${window.location.href}`;

Axios.defaults.baseURL = API_BASE_URL;
Axios.defaults.withCredentials = true;
Axios.defaults.maxRedirects = 0;
Axios.defaults.validateStatus = function (status) {
	return status >= 200 && status < 300;
};

//Redirect to Login upon receiving 401
Axios.interceptors.response.use(null, function (error) {
	if (error?.response?.status === 401) {
		window.location.href = LOGIN_URL;
		return;
	} else {
		throw error;
	}
});

export const QUERY_CLIENT = new QueryClient({
	defaultOptions: {
		queries: {
			retryDelay: 1000,
			placeholderData: keepPreviousData,
			staleTime: 0,
			refetchOnMount: 'always',
			refetchOnWindowFocus: 'always',
			refetchOnReconnect: 'always',
			retry: (failureCount, error) => {
				return axios.isAxiosError(error) && error.response?.status !== 403 ? failureCount < 2 : false;
			},
		},
	},
});
