import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';
import { cn } from '../util/cn';

const AlertRoot = AlertDialogPrimitive.Root;

const AlertTrigger = AlertDialogPrimitive.Trigger;

const alertContent = cva(
	cn('rounded-md overflow-y-auto flex flex-col text-base-content drop-shadow-xl max-h-[80vh] bg-base-100 w-[90vw] '),
	{
		variants: {
			width: {
				sm: ['max-w-sm'],
				md: ['max-w-md'],
				lg: ['max-w-lg'],
				xl: ['max-w-xl'],
				'2xl': ['max-w-2xl'],
				'3xl': ['max-w-3xl'],
				'4xl': ['max-w-4xl'],
				'5xl': ['max-w-5xl'],
			},
		},
		defaultVariants: {
			width: 'md',
		},
	}
);

const alertContentContianer = cva(
	cn(
		'fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen',
		'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=open]:fade-in data-[state=closed]:fade-out'
	),
	{
		variants: {
			animate: {
				fromTop: ['data-[state=open]:slide-in-from-top-10 data-[state=closed]:slide-out-to-top-10'],
				fadeIn: [''],
			},
		},
		defaultVariants: {
			animate: 'fromTop',
		},
	}
);

const AlertOverlay = React.forwardRef<
	React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<AlertDialogPrimitive.Overlay
		ref={ref}
		className={cn(
			'bg-neutral/40 data-[state=open]:animate-in data-[state=open]:fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out fixed inset-0 left-0 top-0 z-50 backdrop-blur-sm',
			className
		)}
		{...props}
	/>
));
AlertOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

export interface IAlertContentProps
	extends React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>,
		VariantProps<typeof alertContent>,
		VariantProps<typeof alertContentContianer> {}

const AlertContent = React.forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Content>, IAlertContentProps>(
	({ className, animate, children, width, ...props }, ref) => (
		<AlertDialogPrimitive.Portal>
			<AlertOverlay />
			<AlertDialogPrimitive.Content
				ref={ref}
				{...props}
				className={alertContentContianer({ animate, className })}
			>
				<div className={alertContent({ width, className })}>{children}</div>
			</AlertDialogPrimitive.Content>
		</AlertDialogPrimitive.Portal>
	)
);

const alertTitle = cva(cn('px-4 py-2 font-semibold text-lg'), {
	variants: {},
	defaultVariants: {},
});

const AlertTitle = ({
	className,
	...props
}: AlertDialogPrimitive.AlertDialogTitleProps & VariantProps<typeof alertTitle>) => (
	<AlertDialogPrimitive.Title {...props} className={alertTitle({ className })} />
);

const alertDescription = cva(cn('px-4 w-3/4'), {
	variants: {},
	defaultVariants: {},
});

const AlertDescription = ({
	className,
	...props
}: AlertDialogPrimitive.AlertDialogDescriptionProps & VariantProps<typeof alertDescription>) => (
	<AlertDialogPrimitive.Description {...props} className={alertDescription({ className })} />
);

export { AlertContent, AlertDescription, AlertRoot, AlertTitle, AlertTrigger };
