import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { FaFloppyDisk } from 'react-icons/fa6';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const SaveButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={FaFloppyDisk} variant={'success'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.save'}>Save {{ value: children || '' }}</Trans>
		</Button>
	);
});

SaveButton.displayName = 'SaveButton';
