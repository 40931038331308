import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { cn } from '../util';

interface ErrorLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	error:
		| FieldError
		| FieldError[]
		| (FieldError | undefined)[]
		| undefined
		| Merge<FieldError, FieldErrorsImpl<object>>;
}

export const ErrorLabel = ({ error, ...props }: ErrorLabelProps) => {
	return Array.isArray(error) ? (
		error.map((err, idx) => (
			<label {...props} className={cn(props.className, 'text-error')} key={idx}>
				{err?.message}
			</label>
		))
	) : (
		<label {...props} className={cn(props.className, 'text-error')}>
			{error?.message}
		</label>
	);
};
