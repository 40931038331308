import { StrykerlabsPlayerPosition } from '@slabs/common';
import { SeasonalStatDistributionObjectDto } from '@slabs/ui-api';
import { cn, SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValue } from '@slabs/ui-common';
import { PlayerPositionTerm } from '../text';
import { groupByStatType } from '../text/StatGroupTerm';

export const PlayerPositionStats: {
	[StrykerlabsPlayerPosition.GK]: (keyof SeasonalStatDistributionObjectDto)[];
	[StrykerlabsPlayerPosition.CB]: (keyof SeasonalStatDistributionObjectDto)[];
	[StrykerlabsPlayerPosition.WB]: (keyof SeasonalStatDistributionObjectDto)[];
	[StrykerlabsPlayerPosition.CM]: (keyof SeasonalStatDistributionObjectDto)[];
	[StrykerlabsPlayerPosition.AM]: (keyof SeasonalStatDistributionObjectDto)[];
	[StrykerlabsPlayerPosition.WF]: (keyof SeasonalStatDistributionObjectDto)[];
	[StrykerlabsPlayerPosition.CF]: (keyof SeasonalStatDistributionObjectDto)[];
	'N/A': [];
} = {
	'N/A': [],
	GK: groupByStatType([
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_progressive_passes',
		'wy_per_90_gk_clean_sheets',
		'wy_per_90_gk_conceded_goals',
		'wy_per_90_gk_saves',
		'wy_per_90_xg_save',
		'wy_percent_gk_aerial_duels_won',
		'wy_per_90_successful_progressive_passes',
		'wy_percent_gk_saves',
		'wy_per_90_gk_shots_against',
		'wy_per_90_gk_exits',
		'wy_per_90_gk_aerial_duels',
		'wy_per_90_gk_aerial_duels_won',
		'wy_per_90_clearances',
	]),
	CB: groupByStatType([
		'sc_totals_top_5_psv_99',
		'sc_per_90_hsr_distance',
		'wy_per_90_opponent_half_recoveries',
		'sc_per_90_distance',
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_progressive_passes',
		'wy_per_90_passes_to_final_third',
		'wy_percent_aerial_duels_won',
		'wy_per_90_dangerous_own_half_losses',
		'wy_percent_dribbles_against_won',
		'wy_per_90_recoveries',
		'wy_percent_new_defensive_duels_won',
		'sc_per_90_sprinting_distance',
		'wy_per_90_fouls',
		'wy_per_90_shots_blocked',
		'wy_per_90_losses',
		'wy_percent_successful_progressive_passes',
		'wy_per_90_dribbles_against',
		'wy_per_90_clearances',
		'wy_percent_successful_passes_to_final_third',
		'wy_percent_successful_long_passes',
		'wy_per_90_interceptions',
		'wy_per_90_defensive_duels',
		'wy_per_90_offensive_duels',
		'wy_per_90_aerial_duels',
		'wy_percent_new_offensive_duels_won',
		'wy_per_90_head_shots',
		'wy_per_90_counterpressing_recoveries',
		'wy_per_90_pressing_duels',
	]),
	WB: groupByStatType([
		'wy_per_90_shot_assists',
		'wy_per_90_xg_assist',
		'wy_per_90_crosses',
		'wy_per_90_progressive_run',
		'wy_per_90_dribbles',
		'sc_totals_top_5_psv_99',
		'sc_per_90_hsr_distance',
		'wy_per_90_opponent_half_recoveries',
		'wy_percent_new_successful_dribbles',
		'sc_per_90_distance',
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_progressive_passes',
		'wy_per_90_passes_to_final_third',
		'wy_per_90_dangerous_own_half_losses',
		'wy_percent_dribbles_against_won',
		'wy_per_90_recoveries',
		'wy_percent_new_defensive_duels_won',
		'sc_per_90_sprinting_distance',
		'wy_per_90_shots_blocked',
		'wy_percent_successful_progressive_passes',
		'wy_per_90_dribbles_against',
		'wy_per_90_clearances',
		'wy_percent_successful_passes_to_final_third',
		'wy_percent_successful_crosses',
		'wy_per_90_interceptions',
		'wy_per_90_defensive_duels',
		'wy_per_90_offensive_duels',
		'wy_percent_new_offensive_duels_won',
		'sc_per_90_count_sprint',
		'wy_per_90_shot_on_target_assists',
		'wy_per_90_touch_in_box',
		'wy_per_90_counterpressing_recoveries',
		'wy_per_90_pressing_duels',
	]),
	CM: groupByStatType([
		'wy_per_90_goals',
		'wy_per_90_xg_shot',
		'wy_per_90_shots',
		'wy_per_90_shot_assists',
		'wy_per_90_xg_assist',
		'wy_per_90_progressive_run',
		'wy_per_90_assists',
		'wy_per_90_shot_on_target_assists',
		'wy_per_90_touch_in_box',
		'wy_percent_shots_on_target',
		'wy_percent_goal_conversion',

		'wy_per_90_dribbles',
		'wy_percent_new_successful_dribbles',
		'wy_per_90_progressive_passes',
		'wy_percent_dribbles_against_won',
		'wy_per_90_smart_passes',
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_passes_to_final_third',
		'wy_per_90_through_passes',
		'wy_per_90_key_passes',
		'wy_per_90_opponent_half_recoveries',
		'wy_per_90_counterpressing_recoveries',
		'wy_percent_new_duels_won',
		'wy_percent_aerial_duels_won',
		'wy_per_90_dangerous_own_half_losses',
		'wy_percent_new_defensive_duels_won',

		'wy_per_90_shots_blocked',
		'wy_percent_successful_progressive_passes',
		'wy_per_90_dribbles_against',
		'wy_per_90_interceptions',
		'wy_per_90_defensive_duels',
		'wy_per_90_offensive_duels',
		'wy_per_90_aerial_duels',
		'wy_per_90_new_duels_won',
		'wy_percent_new_offensive_duels_won',
		'wy_per_90_pressing_duels',

		'sc_per_90_distance',
		'sc_per_90_sprinting_distance',
		'sc_totals_top_5_psv_99',
		'sc_per_90_hsr_distance',
	]),
	AM: groupByStatType([
		'wy_per_90_goals',
		'wy_per_90_xg_shot',
		'wy_per_90_shots',
		'wy_per_90_shot_assists',
		'wy_per_90_xg_assist',
		'wy_per_90_progressive_run',
		'wy_per_90_dribbles',
		'wy_per_90_smart_passes',
		'sc_totals_top_5_psv_99',
		'sc_per_90_hsr_distance',
		'wy_per_90_opponent_half_recoveries',
		'wy_percent_new_successful_dribbles',
		'sc_per_90_distance',
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_progressive_passes',
		'wy_per_90_dangerous_own_half_losses',
		'sc_per_90_sprinting_distance',
		'wy_percent_successful_progressive_passes',
		'wy_per_90_assists',
		'wy_per_90_head_shots',
		'wy_per_90_linkup_plays',
		'wy_per_90_shot_on_target_assists',
		'wy_per_90_touch_in_box',
		'wy_percent_shots_on_target',
		'wy_percent_goal_conversion',
		'wy_per_90_through_passes',
		'wy_per_90_key_passes',
		'wy_per_90_counterpressing_recoveries',
		'wy_per_90_pressing_duels',
	]),
	WF: groupByStatType([
		'wy_per_90_goals',
		'wy_per_90_xg_shot',
		'wy_per_90_shots',
		'wy_per_90_shot_assists',
		'wy_per_90_xg_assist',
		'wy_per_90_crosses',
		'wy_per_90_progressive_run',
		'wy_per_90_dribbles',
		'wy_per_90_smart_passes',
		'sc_totals_top_5_psv_99',
		'sc_per_90_hsr_distance',
		'wy_per_90_opponent_half_recoveries',
		'wy_percent_new_successful_dribbles',
		'sc_per_90_distance',
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_dangerous_own_half_losses',
		'sc_per_90_sprinting_distance',
		'wy_per_90_shots_on_target',
		'wy_percent_successful_crosses',
		'sc_per_90_count_sprint',
		'sc_per_90_sprinting_distance_tip',
		'wy_per_90_assists',
		'wy_per_90_linkup_plays',
		'wy_per_90_shot_on_target_assists',
		'wy_per_90_touch_in_box',
		'wy_percent_shots_on_target',
		'wy_percent_goal_conversion',
		'wy_per_90_through_passes',
		'wy_per_90_key_passes',
		'wy_per_90_counterpressing_recoveries',
		'wy_per_90_pressing_duels',
	]),
	CF: groupByStatType([
		'wy_per_90_goals',
		'wy_per_90_xg_shot',
		'wy_per_90_shots',
		'wy_per_90_shot_assists',
		'wy_per_90_xg_assist',
		'wy_per_90_progressive_run',
		'wy_per_90_shots_on_target',
		'sc_per_90_count_sprint',
		'wy_per_90_assists',
		'wy_per_90_head_shots',
		'wy_per_90_linkup_plays',
		'wy_per_90_touch_in_box',
		'wy_percent_shots_on_target',
		'wy_percent_goal_conversion',
		'wy_per_90_dribbles',
		'wy_percent_new_successful_dribbles',
		'wy_per_90_smart_passes',
		'wy_percent_successful_passes',
		'wy_per_90_passes',
		'wy_per_90_through_passes',
		'wy_per_90_key_passes',
		'wy_per_90_opponent_half_recoveries',
		'wy_per_90_counterpressing_recoveries',
		'wy_percent_new_duels_won',
		'wy_percent_aerial_duels_won',
		'sc_per_90_hsr_distance',
		'wy_per_90_pressing_duels',
		'sc_per_90_distance',
		'sc_per_90_sprinting_distance',
		'sc_totals_top_5_psv_99',
	]),
};

export const AllPlayerCompareStats = Object.values(PlayerPositionStats).reduce(
	(prev, curr) => [...prev, ...curr],
	[] as (keyof SeasonalStatDistributionObjectDto)[]
);

interface PlayerComparePositionSelectProps {
	onSelectChange: (t: StrykerlabsPlayerPosition) => void;
	value: StrykerlabsPlayerPosition;
}

export const PlayerComparePositionSelect = ({ onSelectChange, value }: PlayerComparePositionSelectProps) => {
	return (
		<SelectRoot onValueChange={onSelectChange} value={value}>
			<SelectTrigger className={cn('w-full')}>
				<SelectValue defaultValue={StrykerlabsPlayerPosition.GK} />
			</SelectTrigger>
			<SelectContent className="shadow-lg">
				{Object.values(StrykerlabsPlayerPosition)
					.filter((i) => i !== StrykerlabsPlayerPosition['N/A'])
					.map((position) => (
						<SelectItem key={position} value={position}>
							<div className="flex gap-2 break-none">
								<PlayerPositionTerm term={position} />
							</div>
						</SelectItem>
					))}
			</SelectContent>
		</SelectRoot>
	);
};
