import * as ProgressPrimitive from '@radix-ui/react-progress';
import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

const progress = cva('relative h-2 w-full overflow-hidden rounded-full ', {
	variants: {
		variant: {
			primary: ['bg-primary/30'],
			secondary: ['bg-secondary/30'],
			neutral: ['bg-neutral/30'],
			success: ['bg-success/30'],
			error: ['bg-error/30'],
			warning: ['bg-warning/30'],
			info: ['bg-info/30'],
		},
	},
	defaultVariants: {
		variant: 'primary',
	},
});

const progressBar = cva('flex-1 w-full h-full transition-all ', {
	variants: {
		variant: {
			primary: ['bg-primary'],
			secondary: ['bg-secondary'],
			neutral: ['bg-neutral'],
			success: ['bg-success'],
			error: ['bg-error'],
			warning: ['bg-warning'],
			info: ['bg-info'],
		},
	},
	defaultVariants: {
		variant: 'primary',
	},
});

const Progress = React.forwardRef<
	React.ElementRef<typeof ProgressPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & VariantProps<typeof progress>
>(({ className, value, variant, ...props }, ref) => (
	<ProgressPrimitive.Root ref={ref} className={progress({ className, variant })} {...props}>
		<ProgressPrimitive.Indicator
			className={progressBar({ variant })}
			style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
		/>
	</ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
