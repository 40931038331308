/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Strykerlabs API
 * Strykerlabs API
 * OpenAPI spec version: 0.1
 */

export type ScoutingPlayerTablePlayerFilterDtoGender =
	(typeof ScoutingPlayerTablePlayerFilterDtoGender)[keyof typeof ScoutingPlayerTablePlayerFilterDtoGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScoutingPlayerTablePlayerFilterDtoGender = {
	MALE: 'MALE',
	FEMALE: 'FEMALE',
	BOTH: 'BOTH',
} as const;
