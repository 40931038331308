/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Strykerlabs API
 * Strykerlabs API
 * OpenAPI spec version: 0.1
 */

export type AreaIdFilterValuesItem = (typeof AreaIdFilterValuesItem)[keyof typeof AreaIdFilterValuesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AreaIdFilterValuesItem = {
	EUROPE: 'EUROPE',
	NORTH_AMERICA: 'NORTH_AMERICA',
	SOUTH_AMERICA: 'SOUTH_AMERICA',
	AFRICA: 'AFRICA',
	ASIA: 'ASIA',
	OCEANIA: 'OCEANIA',
	SPECIAL: 'SPECIAL',
} as const;
