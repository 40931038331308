import { UserGetAllPublicDto, UserInfoDto } from '@slabs/ui-api';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type UserInfoStoreState = {
	userInfo: Required<UserInfoDto>;
	setUserInfo: (s: UserInfoDto) => void;
	userMap: Record<string, UserGetAllPublicDto>;
	userArray: UserGetAllPublicDto[];
	setUserMap: (s: Record<string, UserGetAllPublicDto>) => void;
	setUserArray: (s: UserGetAllPublicDto[]) => void;
};

export const useUserData = create(
	immer<UserInfoStoreState>((set) => ({
		userArray: [],
		userMap: {},

		userInfo: {
			id: '',
			avatarUrl: '',
			email: '',
			isStrykerlabsAdmin: false,
			displayName: '',
			allowedTenants: [],
			allowedEnvironments: [],
			selectedTenantFeatureflags: [],
			selectedTenantPermissions: [],
			selectedEnvironmentPermissions: [],
			defaultTenantId: '',
			defaultEnvironmentId: '',
			selectedTenant: '',
			selectedEnvironment: '',
		},
		setUserInfo: (value: UserInfoDto) =>
			set((state) => {
				state.userInfo = {
					...value,
					defaultTenantId: value.defaultTenantId || '',
					defaultEnvironmentId: value.defaultEnvironmentId || '',
					selectedTenant: value.selectedTenant || '',
					selectedEnvironment: value.selectedEnvironment || '',
				};
			}),
		setUserArray: (value) =>
			set((state) => {
				state.userArray = value;
			}),
		setUserMap: (value) =>
			set((state) => {
				state.userMap = value;
			}),
	}))
);
