import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface ColorPicketState {
	recentColors: string[];
	pushColor: (v: string) => void;
}

export const useColorPickerState = create(
	persist(
		immer<ColorPicketState>((set) => ({
			recentColors: ['6419e6', 'd926a9', '1fb2a6', 'fbbd23', '3abff8', 'f87272', '181A2A', '8de1a2'],

			pushColor: (color: string) =>
				set((state) => {
					state.recentColors = [...new Set([color, ...state.recentColors])].slice(0, 8);
				}),
		})),
		{ name: 'color-picker-state', version: 1 }
	)
);
