import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const CancelButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button variant={'primary'} contrast={'outline'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.cancel'}>Cancel {{ value: children || '' }} </Trans>
		</Button>
	);
});

CancelButton.displayName = 'CancelButton';
