import { Trans, useTranslation } from 'react-i18next';

// eslint-disable-next-line react-refresh/only-export-components
export enum LeagueFilter {
	PLAYER = 'player',
	REFERENCE = 'reference',
	NONE = 'none',
}

interface LeagueFilterTermProps {
	term: LeagueFilter;
}

export const LeagueFilterTerm = ({ term }: LeagueFilterTermProps) => {
	const { t } = useTranslation(['scouting']);
	return (
		<>
			{term === LeagueFilter.NONE && (
				<Trans t={t} i18nKey={'scouting:players.compare.leagueFilter.none'}>
					No Filter
				</Trans>
			)}
			{term === LeagueFilter.REFERENCE && (
				<Trans t={t} i18nKey={'scouting:players.compare.leagueFilter.reference'}>
					Reference League
				</Trans>
			)}
			{term === LeagueFilter.PLAYER && (
				<Trans t={t} i18nKey={'scouting:players.compare.leagueFilter.player'}>
					Player League
				</Trans>
			)}
		</>
	);
};
