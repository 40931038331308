import * as HoverCardRadix from '@radix-ui/react-hover-card';
import * as React from 'react';
import { cn } from '../util';

const HoverCard = HoverCardRadix.Root;

const HoverCardTrigger = HoverCardRadix.Trigger;

const HoverCardArrow = React.forwardRef<
	React.ElementRef<typeof HoverCardRadix.Arrow>,
	React.ComponentPropsWithoutRef<typeof HoverCardRadix.Arrow>
>(({ className, ...props }, ref) => (
	<HoverCardRadix.Arrow ref={ref} className={cn('fill-base-100', className)} {...props} />
));
HoverCardArrow.displayName = HoverCardRadix.Arrow.displayName;

const HoverCardContent = React.forwardRef<
	React.ElementRef<typeof HoverCardRadix.Content>,
	React.ComponentPropsWithoutRef<typeof HoverCardRadix.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
	<HoverCardRadix.Portal>
		<HoverCardRadix.Content
			ref={ref}
			align={align}
			sideOffset={sideOffset}
			className={cn(
				'z-50 rounded-md border bg-base-100 text-base-content shadow-md outline-none ',
				'data-[state=open]:animate-in data-[state=open]:fade-in-0  data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
				'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
				className
			)}
			{...props}
		/>
	</HoverCardRadix.Portal>
));
HoverCardContent.displayName = HoverCardRadix.Content.displayName;

export { HoverCard, HoverCardArrow, HoverCardContent, HoverCardTrigger };
