import { ScoutingTenantWorkflowTagDto } from '@slabs/ui-api';
import { AxiosResponse } from 'axios';
import colormap from 'colormap';
import { Theme, useUserSettings } from '../states';

export enum GradientConfig {
	DEFAULT = 'DEFAULT',
	SUNSET = 'SUNSET',
	NATURE = 'NATURE',
	VIRIDIS = 'VIRIDIS',
	SEMAPHORE = 'SEMAPHORE',
}

export const getGradient = (v = '') => {
	const theme = useUserSettings.getState().theme;

	if (theme === Theme.COLOR_BLIND) {
		return GradientConfigs[GradientConfig.VIRIDIS];
	}

	if (Object.values(GradientConfig).includes(v as GradientConfig)) {
		return GradientConfigs[v as GradientConfig];
	}
	return GradientConfigs['DEFAULT'];
};

export const getWorkflowTagColor = (
	allTags: AxiosResponse<ScoutingTenantWorkflowTagDto[], unknown> | undefined,
	tagId: string,
	theme = useUserSettings.getState().theme
) => {
	const totalTagsOnTenant = allTags?.data.length ? allTags?.data.length - 1 : 0;
	const tagIndexOnTenant = allTags?.data.findIndex((t) => t.id === tagId);
	const defaultColor = allTags?.data.at(tagIndexOnTenant ?? 0)?.color;

	const gradient = getGradient();
	const color =
		theme === Theme.COLOR_BLIND && tagIndexOnTenant !== undefined
			? `${gradient[Math.floor((tagIndexOnTenant / totalTagsOnTenant) * 100)]}`
			: `#${defaultColor}`;

	return color;
};

export const GradientConfigs = {
	[GradientConfig.DEFAULT]: colormap({
		colormap: 'portland',
		nshades: 101,
		format: 'hex',
		alpha: 1,
	}),
	[GradientConfig.SUNSET]: colormap({
		colormap: 'temperature',
		nshades: 101,
		format: 'hex',
		alpha: 1,
	}),
	[GradientConfig.NATURE]: colormap({
		colormap: 'copper',
		nshades: 101,
		format: 'hex',
		alpha: 1,
	}),
	[GradientConfig.VIRIDIS]: colormap({
		colormap: 'viridis',
		nshades: 101,
		format: 'hex',
		alpha: 1,
	}),
	[GradientConfig.SEMAPHORE]: colormap({
		colormap: [
			{ index: 0, rgb: [255, 0, 0] },
			{ index: 0.5, rgb: [255, 255, 255] },
			{ index: 1, rgb: [0, 255, 0] },
		],
		nshades: 101,
		format: 'hex',
		alpha: 1,
	}),
};
