import { StrykerlabsPlayerPosition } from '@slabs/common';
import { TenantWyscoutIndexCreateDtoPosition } from '@slabs/ui-api';
import { Trans, useTranslation } from 'react-i18next';

// eslint-disable-next-line react-refresh/only-export-components
export enum PlayerPositions {
	GK = 'GK',
	CB = 'CB',
	WB = 'WB',
	CM = 'CM',
	AM = 'AM',
	WF = 'WF',
	CF = 'CF',
}

export const PlayerPositionTerm = ({
	term,
}: {
	term: StrykerlabsPlayerPosition | TenantWyscoutIndexCreateDtoPosition;
}) => {
	const { t } = useTranslation(['scouting']);
	return (
		<>
			{term === StrykerlabsPlayerPosition.GK && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.goalKeeper'}>
					Goalkeeper
				</Trans>
			)}
			{term === StrykerlabsPlayerPosition.CB && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.centreBack'}>
					Centre Back
				</Trans>
			)}
			{term === StrykerlabsPlayerPosition.WB && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.wingBack'}>
					Wingback
				</Trans>
			)}
			{term === StrykerlabsPlayerPosition.CM && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.centreMidfielder'}>
					Centre Midfielder
				</Trans>
			)}
			{term === StrykerlabsPlayerPosition.AM && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.attackingMidfielder'}>
					Attacking Midfielder
				</Trans>
			)}
			{term === StrykerlabsPlayerPosition.WF && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.wingForward'}>
					Wing Forward
				</Trans>
			)}
			{term === StrykerlabsPlayerPosition.CF && (
				<Trans t={t} i18nKey={'scouting:players.compare.position.centreForward'}>
					Centre Forward
				</Trans>
			)}
		</>
	);
};
