import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { cn } from '../util';

const loader = cva('loading', {
	variants: {
		variant: {
			infinity: ['loading-infinity'],
			ring: ['loading-ring'],
		},
		cssColor: {
			default: [],
			primary: ['text-primary'],
			secondary: ['text-secondary'],
			neutral: ['text-neutral'],
			success: ['text-success'],
			ghost: ['text-ghost'],
			error: ['text-error'],
			warning: ['text-warning'],
			info: ['text-info'],
		},
		size: {
			xs: ['w-3'],
			sm: ['w-6'],
			md: ['w-8'],
			inherit: [''],
			lg: ['w-10'],
		},
	},
	defaultVariants: {
		variant: 'ring',
		cssColor: 'default',
		size: 'inherit',
	},
});

export interface ISpanProps extends React.HTMLAttributes<HTMLSpanElement>, VariantProps<typeof loader> {}

export const Loader = forwardRef<HTMLSpanElement, ISpanProps>(
	({ className, variant, cssColor, size, ...props }, ref) => (
		<span
			ref={ref}
			{...props}
			className={cn(
				loader({
					variant,
					cssColor,
					size,
					className,
				})
			)}
		/>
	)
);
Loader.displayName = 'Loader';
