import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { cn } from '../util';

const toggle = cva('toggle', {
	variants: {
		variant: {
			default: [],
			primary: ['toggle-primary'],
			secondary: ['toggle-secondary'],
			neutral: ['toggle-neutral'],
			success: ['toggle-success'],
			ghost: ['toggle-ghost'],
			error: ['toggle-error'],
			warning: ['toggle-warning'],
			info: ['toggle-info'],
		},
		cssSize: {
			xs: ['toggle-xs'],
			sm: ['toggle-sm'],
			md: [''],
			lg: ['toggle-lg'],
		},
	},
	defaultVariants: {
		variant: 'default',
		cssSize: 'md',
	},
});

const labelCss = cva('font-medium', {
	variants: {
		size: {
			xs: ['text-xs'],
			sm: ['text-sm'],
			md: ['text-md'],
			lg: ['text-lg'],
		},
	},
	defaultVariants: {
		size: 'md',
	},
});

export interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof toggle> {
	isError?: boolean;
	isSuccess?: boolean;
	isLoading?: boolean;
	label?: string;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
	({ className, variant, cssSize, isError, isSuccess, isLoading, label, ...props }, ref) =>
		label ? (
			<label className="flex items-center gap-4 cursor-pointer w-min whitespace-nowrap">
				<input
					ref={ref}
					{...props}
					type="checkbox"
					className={cn(
						toggle({
							variant: isError ? 'error' : isSuccess ? 'success' : variant,
							cssSize,
							className,
						})
					)}
					disabled={props.disabled || isLoading}
				/>
				<span className={labelCss({ size: cssSize })}>{label}</span>
			</label>
		) : (
			<input
				ref={ref}
				{...props}
				type="checkbox"
				className={cn(
					toggle({
						variant: isError ? 'error' : isSuccess ? 'success' : variant,
						cssSize,
						className,
					})
				)}
				disabled={props.disabled || isLoading}
			/>
		)
);

Toggle.displayName = 'Toggle';
