import { cva, VariantProps } from 'class-variance-authority';
import { IconBaseProps, IconType } from 'react-icons/lib';

const icon = cva('', {
	variants: {
		variant: {
			default: [],
			accent: ['text-accent'],
			primary: ['text-primary'],
			secondary: ['text-secondary'],
			neutral: ['text-neutral'],
			'neutral-content': ['text-neutral-content'],
			info: ['text-info'],
			success: ['text-success'],
			warning: ['text-warning'],
			'warning-content': ['text-warning-content'],
			error: ['text-error'],
			'error-content': ['text-error-content'],
		},
		fixedSize: {
			xs: ['h-[12px] w-[12px]'],
			inherit: [],
		},
		cssSize: {
			xss: ['h-2 w-2'],
			xs: ['h-3 w-3'],
			'sm-1': ['h-4 w-4'],
			'sm-2': ['h-5  w-5'],
			inherit: [],
			md: ['h-6  w-6'],
			lg: ['h-8  w-8'],
		},
	},
	defaultVariants: {
		cssSize: 'inherit',
		fixedSize: 'inherit',
		variant: 'default',
	},
});

export interface IIconProps extends VariantProps<typeof icon>, Omit<IconBaseProps, 'size'> {
	icon: IconType;
}

export const Icon = ({ icon: IconType, cssSize, fixedSize, variant, ...props }: IIconProps) => {
	return (
		<IconType
			{...props}
			className={icon({
				cssSize: fixedSize ? undefined : cssSize,
				fixedSize,
				variant,
				className: props.className,
			})}
		/>
	);
};
