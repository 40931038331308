/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { TenantSkillcornerAccessGetDto } from '../models';
import { useTenantSkillcornerAccessControllerGetTenantSkillcornerAccess } from '../query/api';

export const useSkillcornerAccessApi = (
	tenantId: string,
	query?: UseQueryOptions<
		AxiosResponse<TenantSkillcornerAccessGetDto, any>,
		AxiosError<unknown, any>,
		AxiosResponse<TenantSkillcornerAccessGetDto, any>,
		QueryKey
	>
) => {
	const { data: skillCorner, isError: skillCornerError } =
		useTenantSkillcornerAccessControllerGetTenantSkillcornerAccess(tenantId, {
			query: {
				...query,
			},
		});
	const hasSkillcornerAccess = useMemo(
		() => skillCornerError || !!skillCorner?.data.hasAccess,
		[skillCorner?.data.hasAccess, skillCornerError]
	);

	return hasSkillcornerAccess;
};
