import React, { forwardRef } from 'react';
import { IconType } from 'react-icons/lib';
import { Icon, IIconProps } from '../images';

export interface SwapProps extends React.InputHTMLAttributes<HTMLInputElement> {
	iconProps?: Omit<IIconProps, 'icon'>;
	isError?: boolean;
	isSuccess?: boolean;
	isLoading?: boolean;
	on: IconType;
	off: IconType;
}

export const Swap = forwardRef<HTMLInputElement, SwapProps>(
	({ iconProps, isError, isSuccess, isLoading, on, off, ...props }, ref) => (
		<label className="swap btn btn-ghost btn-circle btn-sm">
			{/* this hidden checkbox controls the state */}
			<input {...props} disabled={props.disabled || isLoading} type="checkbox" ref={ref} />

			<Icon
				{...iconProps}
				variant={isError ? 'error' : isSuccess ? 'success' : iconProps?.variant}
				icon={on}
				className="swap-on"
			/>
			<Icon
				{...iconProps}
				variant={isError ? 'error' : isSuccess ? 'success' : iconProps?.variant}
				icon={off}
				className="swap-off"
			/>
		</label>
	)
);

Swap.displayName = 'Swap';
