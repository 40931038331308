import {
	BlockTypeSelect,
	BoldItalicUnderlineToggles,
	ListsToggle,
	MDXEditor,
	MDXEditorProps,
	UndoRedo,
	headingsPlugin,
	listsPlugin,
	quotePlugin,
	toolbarPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import * as React from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = MDXEditorProps;

export const SlabsEditor = forwardRef<React.ElementRef<typeof MDXEditor>, Props>(({ ...props }, ref) => {
	const { t } = useTranslation(['common']);

	return (
		<MDXEditor
			ref={ref}
			placeholder={t('common:editor.placeholder', {
				defaultValue: 'Type here...',
			})}
			plugins={[
				listsPlugin(),
				quotePlugin(),
				...(props.readOnly
					? []
					: [
							toolbarPlugin({
								toolbarClassName: '!bg-base-100 border-b !rounded-none flex items-center w-full',
								toolbarContents: () => (
									<>
										<UndoRedo />
										<BlockTypeSelect />
										<BoldItalicUnderlineToggles />
										<ListsToggle />
									</>
								),
							}),
						]),
				headingsPlugin(),
			]}
			{...props}
		/>
	);
});

SlabsEditor.displayName = 'SlabsEditor';
