import { useSkillcornerAccessApi } from '@slabs/ui-api';
import { Button, Input, Popover, PopoverContent, PopoverTrigger } from '@slabs/ui-common';
import { useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi2';
import { useUserData } from '../../states';
import { NumberMinMaxFilterPlayer, NumberMinMaxFilterStats } from '../../util';
import { ParameterLabelTerm } from '../text';

const FilterOptions = [...NumberMinMaxFilterStats, ...NumberMinMaxFilterPlayer, 'foot'];

interface Props {
	selectedStats: string[];
	onStatSelect: (s: string) => void;
	triggerText?: string;
}

export const AddFilterPopover = ({ selectedStats, onStatSelect, triggerText }: Props) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation(['parameters']);
	const [filter, setFilter] = useState('');
	const [tenantId] = useUserData((state) => [state.userInfo.selectedTenant]);
	const hasSkillcornerAccess = useSkillcornerAccessApi(tenantId);

	const items = useMemo(
		() =>
			FilterOptions.filter(
				(i) =>
					i !== 'wy_totals_matches' &&
					!selectedStats.includes(i) &&
					t(`parameters:${i}.label`).toLowerCase().includes(filter.toLowerCase()) &&
					(hasSkillcornerAccess ? true : !i.startsWith('sc_'))
			),
		[filter, hasSkillcornerAccess, selectedStats, t]
	);

	const onOpenChange = (b: boolean) => {
		if (b) {
			setFilter('');
		}
		setOpen(b);
	};
	const containerRef = useRef(null);

	return (
		<div ref={containerRef}>
			<Popover open={open} onOpenChange={onOpenChange}>
				<PopoverTrigger asChild>
					<Button size={'xs'} variant={'neutral'} contrast={'outline'} icon={HiPlus}>
						<span className="">
							{triggerText ||
								t('scouting:players.table.config.filters.add.button', { defaultValue: 'Add Filter' })}
						</span>
					</Button>
				</PopoverTrigger>
				<PopoverContent align="end" side="bottom" className="p-2 " container={containerRef.current}>
					<div className="flex flex-col gap-1 pb-2">
						<label className="text-xs font-bold opacity-80">
							<Trans i18nKey={'scouting:players.table.config.filters.search.label'}>Search Filters</Trans>
						</label>
						<Input
							value={filter}
							onChange={(e) => setFilter(e.currentTarget.value)}
							cssSize={'sm'}
							rounded={'sm'}
						/>
					</div>
					<div className="flex flex-col py-2 overflow-y-auto text-sm max-h-52" role="list">
						{items.map((item) => (
							<div
								key={item}
								className="py-0.5 px-2 text-sm cursor-pointer hover:bg-primary/20 hover:text-primary text-ellipsis shrink-0 overflow-x-hidden w-[260px]"
								role="listitem"
								onClick={() => onStatSelect(item)}
							>
								<ParameterLabelTerm term={item} />
							</div>
						))}
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
};
