import { PartialBy, StrykerlabsPlayerPosition } from '@slabs/common';
import {
	AreaIdFilter,
	AreaIdFilterValuesItem,
	ContractDurationFilter,
	FootFilter,
	MarketValueFilter,
	NumberMinMaxFilter,
	PlayerProfileDto,
	ScoutingPlayerTablePlayerFilterDtoGender,
	ScoutingPlayerTablePostBodyParamsDto,
	WyscoutAreasGetDto,
	WyscoutSearchCompetitionsGetDto,
	WyscoutSearchTeamsGetDto,
} from '@slabs/ui-api';
import update from 'immutability-helper';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { NumberMinMaxFilterStats } from '../util';

interface DistinctFilter<T> {
	values?: T[];
	include?: boolean;
}
export const FILTER_CONFIG_VERSION = 2;

export interface FilterConfigState {
	version: number;

	stats: {
		[key in NumberMinMaxFilterStats]?: NumberMinMaxFilter;
	};
	draftStats: FilterConfigState['stats'];

	player: {
		gender?: ScoutingPlayerTablePlayerFilterDtoGender;
		id?: DistinctFilter<PlayerProfileDto>;
		age?: NumberMinMaxFilter;
		currentTeam?: DistinctFilter<WyscoutSearchTeamsGetDto>;
		position?: StrykerlabsPlayerPosition[];
		foot?: FootFilter;
		height?: NumberMinMaxFilter;
		weight?: NumberMinMaxFilter;
		birthArea?: DistinctFilter<WyscoutAreasGetDto>;
		marketValue?: MarketValueFilter;
		contractDurationRemaining?: ContractDurationFilter;
	};
	draftPlayer: FilterConfigState['player'];

	competition: {
		areaIds?: AreaIdFilter;
		competitionIds?: DistinctFilter<WyscoutSearchCompetitionsGetDto>;
		divisionLevel?: NumberMinMaxFilter;
	};

	draftCompetition: FilterConfigState['competition'];

	season: {
		year: number;
		playerSeasonTeamIds?: DistinctFilter<WyscoutSearchTeamsGetDto>;
	};
	draftSeason: FilterConfigState['season'];

	updateAll: (
		object: PartialBy<Pick<FilterConfigState, 'stats' | 'player' | 'competition' | 'version' | 'season'>, 'season'>
	) => void;

	removeStat: (key: keyof FilterConfigState['stats']) => void;
	updateStat: <T extends keyof FilterConfigState['stats']>(key: T, filter: FilterConfigState['stats'][T]) => void;

	updatePlayer: <T extends keyof FilterConfigState['player']>(key: T, filter: FilterConfigState['player'][T]) => void;
	removePlayer: (key: keyof FilterConfigState['player']) => void;

	removeCompetition: (key: keyof FilterConfigState['competition']) => void;
	updateCompetition: <T extends keyof FilterConfigState['competition']>(
		key: T,
		filter: FilterConfigState['competition'][T]
	) => void;

	removeSeason: (key: keyof FilterConfigState['season']) => void;
	updateSeason: <T extends keyof FilterConfigState['season']>(key: T, filter: FilterConfigState['season'][T]) => void;

	saveDraft: () => void;
	reset: () => void;
	cancel: () => void;
}

const defaultComeptition = {
	areaIds: {
		values: Object.values(AreaIdFilterValuesItem),
		include: true,
	},
} satisfies ScoutingPlayerTablePostBodyParamsDto['competitionFilter'];

const defaultPlayer = {
	position: Object.values(StrykerlabsPlayerPosition),
	gender: ScoutingPlayerTablePlayerFilterDtoGender.BOTH,
};

// ScoutingPlayerTablePostBodyParamsDto

export const useFilterConfig = create(
	immer<FilterConfigState>((set) => ({
		version: FILTER_CONFIG_VERSION,
		stats: {},
		draftStats: {},
		competition: defaultComeptition,
		draftCompetition: defaultComeptition,
		player: defaultPlayer,
		draftPlayer: defaultPlayer,
		season: {
			year: 2024,
		},
		draftSeason: {
			year: 2024,
		},

		removeStat: (key: keyof FilterConfigState['stats']) =>
			set((state) => {
				state.draftStats = update(state.draftStats, {
					$unset: [key],
				});
			}),
		updateStat: <T extends keyof FilterConfigState['stats']>(key: T, filter: FilterConfigState['stats'][T]) =>
			set((state) => {
				state.draftStats = update(state.draftStats, {
					[key]: {
						$set: filter,
					},
				});
			}),
		removePlayer: (key: keyof FilterConfigState['player']) =>
			set((state) => {
				state.draftPlayer = update(state.draftPlayer, {
					$unset: [key],
				});
			}),
		updatePlayer: <T extends keyof FilterConfigState['player']>(key: T, filter: FilterConfigState['player'][T]) =>
			set((state) => {
				state.draftPlayer = update(state.draftPlayer, {
					[key]: {
						$set: filter,
					},
				});
			}),
		updateAll: (item) =>
			set((state) => {
				state.draftPlayer = item.player;
				state.draftCompetition = item.competition;
				state.draftStats = item.stats;
				state.version = item.version;
				state.draftSeason = { ...item.season, year: item.season?.year || 2024 };
			}),

		removeCompetition: (key: keyof FilterConfigState['competition']) =>
			set((state) => {
				state.draftCompetition = update(state.draftCompetition, {
					$unset: [key],
				});
			}),
		updateCompetition: <T extends keyof FilterConfigState['competition']>(
			key: T,
			filter: FilterConfigState['competition'][T]
		) =>
			set((state) => {
				state.draftCompetition = update(state.draftCompetition, {
					[key]: {
						$set: filter,
					},
				});
			}),

		removeSeason: (key: keyof FilterConfigState['season']) =>
			set((state) => {
				state.draftSeason = update(state.draftSeason, {
					$unset: [key],
				});
			}),
		updateSeason: <T extends keyof FilterConfigState['season']>(key: T, filter: FilterConfigState['season'][T]) =>
			set((state) => {
				state.draftSeason = update(state.draftSeason, {
					[key]: {
						$set: filter,
					},
				});
			}),

		saveDraft: () => {
			set((state) => {
				state.stats = state.draftStats;
				state.player = state.draftPlayer;
				state.competition = state.draftCompetition;
				state.season = { ...state.draftSeason, year: state.draftSeason.year || 2024 };
			});
		},
		reset: () =>
			set((state) => {
				state.stats = {};
				state.player = defaultPlayer;
				state.competition = defaultComeptition;
				state.season = { year: 2024 };
				state.draftCompetition = defaultComeptition;
				state.draftPlayer = defaultPlayer;
				state.draftStats = {};
				state.draftSeason = { year: 2024 };
			}),
		cancel: () =>
			set((state) => {
				state.draftCompetition = state.competition;
				state.draftPlayer = state.player;
				state.draftStats = state.stats;
				state.draftSeason = state.season;
			}),
	}))
);
