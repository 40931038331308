import { cva, type VariantProps } from 'class-variance-authority';
import { HTMLAttributes, ReactNode, forwardRef, useId } from 'react';
import { FieldError } from 'react-hook-form';
import { cn } from '../util';
import { ErrorLabel } from './ErrorLabel';

const fieldset = cva('flex flex-col gap-1', {
	variants: {
		textSize: {
			xs: ['text-xs'],
			sm: ['text-sm'],
			md: ['text-md'],
			lg: ['text-lg'],
		},
		animate: {
			true: ['animate-in duration-500 fade-in-0'],
			false: [],
		},
		isHidden: {
			true: ['hidden animate-out duration-1000 fade-out-0'],
			false: [],
		},
	},
	defaultVariants: {
		textSize: 'sm',
		animate: false,
		isHidden: false,
	},
});

type InputProps = Pick<
	HTMLAttributes<HTMLInputElement>,
	'aria-invalid' | 'aria-labelledby' | 'aria-describedby' | 'aria-errormessage' | 'id'
>;

interface FaaC {
	children?: ((aria: InputProps) => ReactNode) | ReactNode;
}

export interface IFieldsetProps
	extends Omit<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, 'children'>,
		VariantProps<typeof fieldset>,
		FaaC {
	label?: string;
	errorMessage?: string | string[];
	errorPosition?: 'bottom' | 'top';
	error?: FieldError | FieldError[] | undefined | (FieldError | undefined)[];
}

export const Fieldset = forwardRef<HTMLFieldSetElement, IFieldsetProps>(
	(
		{
			className,
			textSize,
			children,
			label,
			errorMessage,
			isHidden,
			animate,
			error,
			errorPosition = 'top',
			...props
		},
		ref
	) => {
		const labelId = useId();
		const inputId = useId();
		const errorId = useId();

		const inputProps = {
			id: inputId,
			'aria-invalid': !!errorMessage,
			'aria-labelledby': labelId,
			'aria-errormessage': errorMessage ? errorId : undefined,
		};

		return (
			<fieldset
				ref={ref}
				{...props}
				className={cn(
					fieldset({
						textSize,
						animate,
						isHidden,
						className,
					}),
					{ 'gap-0': !error && !errorMessage }
				)}
			>
				<div className="flex items-center justify-between gap-1.5">
					<label className="" htmlFor={inputId} id={labelId}>
						{label}
					</label>
					<div className="flex flex-col gap-0.5 leading-none" id={errorId}>
						{errorMessage && errorPosition === 'top' && (
							<label className={cn('text-error')} htmlFor={inputId}>
								{errorMessage}
							</label>
						)}

						{error && errorPosition === 'top' && <ErrorLabel htmlFor={inputId} error={error} />}
					</div>
				</div>
				{/* The hidden check forces a reload of default values for inputs */}
				{isHidden || (typeof children !== 'function' ? children : children(inputProps))}
				{(error || errorMessage) && (
					<div className="flex items-center justify-end">
						{errorMessage && errorPosition === 'bottom' && (
							<label className={cn(' text-error')} htmlFor={inputId} id={errorId}>
								{errorMessage}
							</label>
						)}

						{error && errorPosition === 'bottom' && <ErrorLabel htmlFor={inputId} error={error} />}
					</div>
				)}
			</fieldset>
		);
	}
);
Fieldset.displayName = 'Fieldset';
