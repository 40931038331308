import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { HiPencil } from 'react-icons/hi2';
import { Button } from '../Button';
import { ILocalisedButtonProps } from './LocalisedButton';

export const EditButton = forwardRef<HTMLButtonElement, ILocalisedButtonProps>(({ children, ...props }, ref) => {
	return (
		<Button icon={HiPencil} variant={'ghost'} {...props} ref={ref}>
			<Trans i18nKey={'common:actions.edit'}>Edit {{ value: children || '' }}</Trans>
		</Button>
	);
});

EditButton.displayName = 'EditButton';
