import { StrykerlabsPlayerPosition } from '@slabs/common';
import { PlayerProfileDto, SeasonalStatDistributionObjectDto, WyscoutPlayerSeasonsGetDto } from '@slabs/ui-api';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PlayerPositionStats } from '../components/inputs/PlayerComparePositionSelect';
import { LeagueFilter } from '../components/text/LeagueFilterTerm';
import { useMenuState } from './menu-state';

export interface PinnedPlayersState {
	indexId: string | null;
	setIndexId: (n: string | null) => void;

	singleRadarChart: boolean;
	toggleRadarChart: () => void;

	players: PlayerProfileDto[];
	addPlayer: (p: PlayerProfileDto) => void;
	setPlayers: (p: PlayerProfileDto[]) => void;
	removePlayer: (id: string) => void;

	playerSeason: Record<string, WyscoutPlayerSeasonsGetDto | undefined>;
	setPlayerSeason: (s: WyscoutPlayerSeasonsGetDto | undefined, pId: string) => void;

	// TODO: consider rmeoving these after we remove old player compare
	playerIndexes: Record<string, number>;
	setPlayerIndex: (slabsPlayerId: string, index: number) => void;

	setCompetitionId: (id: number) => void;
	competitionId: number;
	setSeasonId: (id: number) => void;
	seasonId: number;

	position: StrykerlabsPlayerPosition;
	setPosition: (p: StrykerlabsPlayerPosition) => void;

	toggleCompactView: (...key: (keyof SeasonalStatDistributionObjectDto)[]) => void;
	statsCompactView: (keyof SeasonalStatDistributionObjectDto)[];
	leagueFilter: LeagueFilter;
	setLeagueFilter: (f: LeagueFilter) => void;
	isCompactView: boolean;
}

export const usePlayerCompareState = create(
	persist(
		immer<PinnedPlayersState>((set) => ({
			indexId: null,
			setIndexId(n) {
				set((state) => {
					state.indexId = n;
				});
			},

			singleRadarChart: false,
			toggleRadarChart: () => {
				set((state) => {
					state.singleRadarChart = !state.singleRadarChart;
				});
			},
			players: [],

			setPlayers: (p) => {
				set((state) => {
					state.players = p;
					const setPlayers = useMenuState.getState().setPlayers;
					setPlayers(state.players.length);
				});
			},
			addPlayer: (p) => {
				set((state) => {
					state.players = [...state.players, p];
					const setPlayers = useMenuState.getState().setPlayers;
					setPlayers(state.players.length);
				});
			},
			removePlayer: (id) => {
				set((state) => {
					state.players = state.players.filter((p) => p.strykerlabsPlayerId !== id);
					const setPlayers = useMenuState.getState().setPlayers;
					setPlayers(state.players.length);
				});
			},

			playerIndexes: {},
			setPlayerIndex(playerId, index) {
				set((state) => {
					state.playerIndexes[playerId] = index;
				});
			},

			playerSeason: {},
			setPlayerSeason(s, pId) {
				set((state) => {
					state.playerSeason[pId] = s;
				});
			},

			seasonId: 188921,
			competitionId: 108,
			position: StrykerlabsPlayerPosition.CM,

			setSeasonId: (p) => {
				set((state) => {
					state.seasonId = p;
				});
			},

			setCompetitionId: (p) => {
				set((state) => {
					state.competitionId = p;
				});
			},

			setPosition: (p) => {
				set((state) => {
					const isCompactView = PlayerPositionStats[p].every((s) => state.statsCompactView.includes(s));

					if (isCompactView) {
						state.statsCompactView = [...state.statsCompactView, ...PlayerPositionStats[p]];
					}
					state.isCompactView = isCompactView;
					state.position = p;
				});
			},
			isCompactView: false,
			statsCompactView: [] as (keyof SeasonalStatDistributionObjectDto)[],
			leagueFilter: LeagueFilter.NONE,

			setLeagueFilter: (p) => {
				set((state) => {
					state.leagueFilter = p;
				});
			},
			toggleCompactView: (...key) => {
				set((state) => {
					if (key.every((k) => state.statsCompactView.includes(k))) {
						state.statsCompactView = state.statsCompactView.filter((s) => !key.includes(s));
					} else {
						state.statsCompactView = [...state.statsCompactView, ...key];
					}

					const isCompactView = PlayerPositionStats[state.position].every((s) =>
						state.statsCompactView.includes(s)
					);

					state.isCompactView = isCompactView;
				});
			},
		})),
		{ name: 'player-compare-storage', version: 1 }
	)
);
