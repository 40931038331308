import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { cn } from '../util';

const form = cva('grow grid gap-4 w-full', {
	variants: {},
	defaultVariants: {},
});

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement>, VariantProps<typeof form> {
	cols?: number;
}

export const Form = forwardRef<HTMLFormElement, FormProps>(({ className, cols = 2, ...props }, ref) => {
	return (
		<form
			ref={ref}
			className={cn(
				form({
					className,
				})
			)}
			style={{
				gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
			}}
			{...props}
		/>
	);
});

Form.displayName = 'form';
